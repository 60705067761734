import React from 'react';
import styles from './Navigation.module.css';

interface NextButtonProps {
  onClick?: () => void;
  label?: string;
  disabled?: boolean;
}

const defaultProps = {
  label: 'Next',
};

const NextButton: React.FC<NextButtonProps> = ({ 
  onClick, 
  label = defaultProps.label,
  disabled = false 
}) => {
  return (
    <button 
      className={styles.nextButton} 
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export default NextButton; 