import React from 'react';
import styles from './TrialOption.module.css';

interface HelpTextProps {
  text?: string;
}

const defaultProps = {
  text: 'Your contribution helps keep Flatsharing available to people around the world who need it most.',
};

const HelpText: React.FC<HelpTextProps> = ({ 
  text = defaultProps.text 
}) => {
  return (
    <div className={styles.helpText}>
      {text}
    </div>
  );
};

export default HelpText; 