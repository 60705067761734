// Utility functions for localStorage operations

/**
 * Save onboarding data to localStorage
 * @param key The data key (e.g., 'industry', 'location')
 * @param value The value to store
 */
export const saveOnboardingData = (key: string, value: any): void => {
  try {
    // Get existing data or initialize an empty object
    const existingData = getOnboardingData();
    
    // Update the data
    const updatedData = {
      ...existingData,
      [key]: value
    };
    
    // Save back to localStorage
    localStorage.setItem('onboardingData', JSON.stringify(updatedData));
    console.log(`Saved to localStorage: ${key} = ${JSON.stringify(value)}`);
  } catch (error) {
    console.error('Error saving onboarding data to localStorage:', error);
  }
};

/**
 * Get all onboarding data from localStorage
 * @returns Record<string, any> - All saved onboarding data
 */
export const getOnboardingData = (): Record<string, any> => {
  try {
    const data = localStorage.getItem('onboardingData');
    return data ? JSON.parse(data) : {};
  } catch (error) {
    console.error('Error getting onboarding data from localStorage:', error);
    return {};
  }
};

/**
 * Get specific onboarding data with option to parse comma-separated values
 * @param key The data key to retrieve
 * @param parseArray Whether to parse comma-separated values into an array
 * @returns The value for the specified key, optionally parsed as an array
 */
export const getOnboardingDataItem = (key: string, parseArray: boolean = false): any => {
  try {
    const data = getOnboardingData();
    const value = data[key];
    
    // If value is a string and parseArray is true, try to split it into an array
    if (typeof value === 'string' && parseArray) {
      const parsedValue = value.split(',');
      console.log(`Loaded from localStorage: ${key} = ${JSON.stringify(parsedValue)} (parsed from string)`);
      return parsedValue;
    }
    
    console.log(`Loaded from localStorage: ${key} = ${JSON.stringify(value)}`);
    return value;
  } catch (error) {
    console.error(`Error getting onboarding data item '${key}':`, error);
    return parseArray ? [] : null;
  }
};

/**
 * Clear all onboarding data from localStorage
 */
export const clearOnboardingData = (): void => {
  try {
    localStorage.removeItem('onboardingData');
    console.log('All onboarding data cleared from localStorage');
  } catch (error) {
    console.error('Error clearing onboarding data from localStorage:', error);
  }
}; 