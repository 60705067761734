import React from 'react';
import { 
  ExploreIcon, 
  ExploreHeading, 
  ExploreDescription 
} from '../components/Explore';

interface Step7Props {
  onNext: () => void;
  onPrev: () => void;
}

const Step7: React.FC<Step7Props> = ({ onNext, onPrev }) => {
  return (
    <div className="onboarding-step">
      <div className="step-content">
        <ExploreIcon />
        <ExploreHeading text="Explore Apartments Worldwide" />
        <ExploreDescription 
          text="Discover apartments in countries like UK, France, Italy, USA, Germany, Netherlands, Russia, Thailand, Bali, Armenia, Georgia, Britain, France, the United States, Switzerland, Serbia, Turkey, Montenegro, Sweden, Cyprus, and more."
        />
      </div>
    </div>
  );
};

export default Step7; 