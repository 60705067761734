import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Registration.module.css';

const LoginLink: React.FC = () => {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/login');
  };

  return (
    <div className={styles.loginText}>
      Already have an account?{' '}
      <span className={styles.loginLink} onClick={handleLoginClick}>
        Log in
      </span>
    </div>
  );
};

export default LoginLink; 