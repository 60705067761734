import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define the type for the onboarding state
interface OnboardingState {
  currentStep: number;
  totalSteps: number;
  completed: boolean;
  data: Record<string, any>; // To store user inputs at each step
}

// Initial state for onboarding
const initialState: OnboardingState = {
  currentStep: 1,
  totalSteps: 17,
  completed: false,
  data: {},
};

// Create the onboarding slice
const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    // Move to the next step
    nextStep: (state) => {
      if (state.currentStep < state.totalSteps) {
        state.currentStep += 1;
      }
      
      // Mark as completed if we've reached the last step
      if (state.currentStep === state.totalSteps) {
        state.completed = true;
      }
    },
    
    // Move to the previous step
    prevStep: (state) => {
      if (state.currentStep > 1) {
        state.currentStep -= 1;
      }
    },
    
    // Jump to a specific step
    goToStep: (state, action: PayloadAction<number>) => {
      const targetStep = action.payload;
      if (targetStep >= 1 && targetStep <= state.totalSteps) {
        state.currentStep = targetStep;
      }
    },
    
    // Save data for the current step
    saveStepData: (state, action: PayloadAction<Record<string, any>>) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
    
    // Reset onboarding process
    resetOnboarding: (state) => {
      state.currentStep = 1;
      state.completed = false;
      state.data = {};
    },
  },
});

// Export actions and reducer
export const { 
  nextStep, 
  prevStep, 
  goToStep, 
  saveStepData, 
  resetOnboarding 
} = onboardingSlice.actions;

export default onboardingSlice.reducer; 