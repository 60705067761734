import React from 'react';
import styles from './Gift.module.css';

interface GiftTitleProps {
  text: string;
}

const defaultProps = {
  text: "Here's your welcome gift",
};

const GiftTitle: React.FC<GiftTitleProps> = ({ 
  text = defaultProps.text 
}) => {
  return (
    <div className={styles.title}>
      {text}
    </div>
  );
};

export default GiftTitle; 