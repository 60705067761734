import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const productsApi = createApi({
  reducerPath: "productsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_FLAT_API,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");

      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  
  endpoints: (builder) => ({
    telegramVerify: builder.mutation({
      query: (userName) => ({
        url: `/telegram-auth/send-confirmation?username=${userName}`,
        method: "POST",
      }),
    }),

    listing: builder.query({
      query: ({
        offset,
        limit = 10,
        direction,
        check_in_date,
        check_out_date,
        sorting,
      }) => ({
        url: `/listings?limit=${limit}${offset ? `&offset=${offset}` : ''}${
          direction ? `&direction=${direction}` : ''
        }${check_in_date ? `&check_in_date=${check_in_date}` : ''}${
          check_out_date ? `&check_out_date=${check_out_date}` : ''
        }${sorting ? `&sorting=${sorting}` : ''}`,
        headers: {
          'Accept-Language': 'en'
        }
      }),
    }),

    getOneFlat: builder.query({
      query: ({ id }) => ({
        url: `/listings/${id}`,
        headers: {
          'Accept-Language': 'en'
        }
      }),
    }),

    getUsersProducts: builder.query({
      query: ({ statuses, limit = 50, posted_only = false }) => {
        const queryString = statuses
          ? statuses.map((status: string) => `statuses=${status}`).join("&")
          : "";

        return {
          url: `/listings/user/me?limit=${limit}&posted_only=${posted_only}${queryString ? `&${queryString}` : ''}`,
          headers: {
            'Accept-Language': 'en'
          }
        };
      },
    }),

    addFlat: builder.mutation({
      query: (data: unknown) => ({
        url: "/listings",
        method: "POST",
        body: data,
      }),
    }),

    uploadImages: builder.mutation({
      query: ({ data, id }) => {
        return {
          url: `/listings/${id}/pictures`,
          method: "POST",
          body: data,
        };
      },
    }),

    editAdvert: builder.mutation({
      query: ({ listing_id, data }) => {
        return {
          url: `/listings/me/${listing_id}`,
          method: "PATCH",
          body: data,
        };
      },
    }),

    deleteAdvert: builder.mutation({
      query: ({ listing_id }) => {
        return {
          url: `/listings/${listing_id}`,
          method: "DELETE",
        };
      },
    }),

    listingPictureUpdate: builder.mutation({
      query: ({ listing_picture_id, images }) => {
        return {
          url: `/listing_pictures/${listing_picture_id}`,
          method: "PATCH",
          body: images,
        };
      },
    }),

    getUserInfo: builder.query({
      query: () => "/users/me",
    }),

    uploadMultipleImages: builder.mutation({
      query: ({ data, listing_id, status = 'used' }) => ({
        url: `/listing_pictures/upload?status=${status}&listing_id=${listing_id}`,
        method: 'POST',
        body: data,
      }),
    }),

    uploadMultipleImagesByUrls: builder.mutation({
      query: ({ urls, listing_id, status = 'used' }) => ({
        url: `/listing_pictures/upload_by_urls?status=${status}&listing_id=${listing_id}`,
        method: 'POST',
        body: urls,
      }),
    }),

    deleteAllListingPictures: builder.mutation({
      query: (listing_id) => ({
        url: `/listing_pictures/all/${listing_id}`,
        method: 'DELETE',
      }),
    }),

    fbTracking: builder.mutation({
      query: (data) => ({
        url: "/fb_ads/fb_tracking",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useListingQuery,
  useGetOneFlatQuery,
  useAddFlatMutation,
  useGetUsersProductsQuery,
  useUploadImagesMutation,
  useEditAdvertMutation,
  useDeleteAdvertMutation,
  useListingPictureUpdateMutation,
  useGetUserInfoQuery,
  useTelegramVerifyMutation,
  useUploadMultipleImagesMutation,
  useUploadMultipleImagesByUrlsMutation,
  useDeleteAllListingPicturesMutation,
  useFbTrackingMutation,
} = productsApi;
