import React from 'react';
import styles from './Registration.module.css';

interface InputFieldProps {
  text?: string;
  type?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
}

const defaultProps = {
  text: 'Email Adress',
  type: 'email',
};

const InputField: React.FC<InputFieldProps> = ({ 
  text = defaultProps.text,
  type = defaultProps.type,
  onChange,
  value 
}) => {
  return (
    <input 
      className={styles.inputField} 
      placeholder={text}
      type={type}
      onChange={onChange}
      value={value}
    />
  );
};

export default InputField; 