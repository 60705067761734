import styles from './Spinner.module.css';

interface SpinnerProps {
  overlay?: boolean;
}

const Spinner = ({ overlay = false }: SpinnerProps) => {
  if (overlay) {
    return (
      <div className={styles.overlayContainer}>
        <div className={styles.overlaySpinner}></div>
      </div>
    );
  }
  
  return (
    <div className={styles.spinnerContainer}>
      <div className={styles.spinner}></div>
    </div>
  );
};

export default Spinner; 