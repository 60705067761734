import React from 'react';
import styles from './Registration.module.css';

interface ButtonProps {
  label?: string;
  onClick?: () => void;
  disabled?: boolean;
}

const defaultProps = {
  label: 'Join Flatsharing',
  disabled: false,
};

const Button: React.FC<ButtonProps> = ({ 
  label = defaultProps.label,
  onClick,
  disabled = defaultProps.disabled
}) => {
  return (
    <button 
      className={styles.button}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export default Button; 