import { usePaymentMutation } from '../redux/auth/authApi';

interface PaymentData {
  email?: string;
  id?: string;
}

export const createPaymentData = (userEmail: PaymentData, trialDays?: number) => ({
  amount: 28,
  description: `Access to FlatSharing Community for 1 year for ${userEmail?.email} ${userEmail?.id}`,
  recurring_period: "year",
  trial_days: trialDays,
  quantity: 1,
  user_id: userEmail?.id,
  period: 366,
});

export const useHandlePayment = () => {
  const [paymentRequest] = usePaymentMutation();
  
  const handlePayment = async (userInfo: PaymentData, openInNewTab = true) => {
    const data = createPaymentData(userInfo);
    
    const { data: paymentResponse } = await paymentRequest({ data });
    
    if (paymentResponse?.stripe_link) {
      if (openInNewTab) {
        window.open(paymentResponse.stripe_link, "_blank");
      } else {
        window.location.href = paymentResponse.stripe_link;
      }
      return paymentResponse;
    }
  };

  return handlePayment;
}; 