import React, { ReactNode } from 'react';
import styles from './Header.module.css';

interface HeaderProps {
  children: ReactNode;
}

const Header: React.FC<HeaderProps> = ({ children }) => {
  return (
    <div className={styles.header}>
      {children}
    </div>
  );
};

export default Header; 