import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import BrowseApartments from "../../entities/Browse/Header";
import { useListingQuery } from "../../app/redux/product/apiProducts";
import BrowseList from "./list/BrowseList";
import Pagination from "./list/Pagination";
import { useModal } from "../../app/helpers/hooks/useModal";
import Login from "../../entities/auth/login/Login";
import Questions from "../../entities/questions/Questions";
import TravelWithFlatSharing from "../../entities/travelWithFlatSharing/TravelWithFlatSharing";
import Spinner from "../../shared/ui/Spinner";

const Browse = () => {
  const [searchParams] = useSearchParams();

  const user = localStorage.getItem("token");

  const { ModalComponent, closeModal, isOpen, openModal } = useModal();

  const currentPage = parseInt(searchParams.get("page") || "1");
  const limit = 10;
  const offset = (currentPage - 1) * limit;

  const { data, isLoading, refetch } = useListingQuery({
    offset,
    limit,
    direction: searchParams.get("direction"),
    check_in_date: searchParams.get("check_in_date"),
    check_out_date: searchParams.get("check_out_date"),
    sorting: searchParams.get("sorting"),
  });

  const handleOpenModal = () => {
    if (!user) {
      openModal();
    }
  };

  useEffect(() => {
    handleOpenModal();
  }, []);

  useEffect(() => {
    refetch();
  }, [searchParams]);

  if (isLoading) return <Spinner />;

  return (
    <div>
      <BrowseApartments />
      {data ? (
        <>
          <BrowseList data={data} />
          <Pagination />
        </>
      ) : (
        <div>No data found</div>
      )}

      {
        <ModalComponent>
          <Login
            isOpen={isOpen}
            closeModal={closeModal}
            openModal={openModal}
          />
        </ModalComponent>
      }
      {/* <PopularDestination /> */}
      <Questions />
      <TravelWithFlatSharing />
    </div>
  );
};

export default Browse;
