import React, { useEffect } from 'react';
import { QuestionText, OptionButton, MoneyIcon } from '../components/Question';
import useOnboardingData from '../../../hooks/useOnboardingData';
import styles from '../components/Navigation/Navigation.module.css';

interface Step5Props {
  onNext: () => void;
  onPrev: () => void;
}

// Travel spending options
const spendingOptions = [
  { id: 'less1k', label: 'Less than $1k' },
  { id: '1k-3k', label: '$1k - $3k' },
  { id: '3k-6k', label: '$3k - $6k' },
  { id: 'more6k', label: 'More than $6k' }
];

const Step5: React.FC<Step5Props> = ({ onNext, onPrev }) => {
  // Use our custom hook for data management
  const { data: selectedOption, setData: setSelectedOption, saveData } = 
    useOnboardingData<string | null>(null, 'travelSpending');

  const handleOptionClick = (optionId: string) => {
    setSelectedOption(optionId);
    
    // Save data and move to next step after a short delay
    setTimeout(() => {
      saveData(optionId); // Explicitly save the selected option
      onNext();
    }, 300);
  };

  return (
    <div className="onboarding-step">
      <div className="step-content">
        <MoneyIcon />
        <QuestionText text="How much money do you spend on one travel" />
        
        <div className="options-container">
          {spendingOptions.map((option) => (
            <OptionButton
              key={option.id}
              label={option.label}
              selected={selectedOption === option.id}
              onClick={() => handleOptionClick(option.id)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Step5; 