import React, { useState } from 'react';
import styles from './Registration.module.css';
import eyeIcon from '../../../../assets/icons/eye.svg';
import eyeOffIcon from '../../../../assets/icons/eye-off.svg';

interface PasswordFieldProps {
  text?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
}

const defaultProps = {
  text: 'Password',
};

const PasswordField: React.FC<PasswordFieldProps> = ({ 
  text = defaultProps.text,
  onChange,
  value 
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={styles.passwordContainer}>
      <input 
        className={styles.passwordField} 
        placeholder={text}
        type={showPassword ? 'text' : 'password'}
        onChange={onChange}
        value={value}
      />
      <button 
        type="button"
        className={styles.eyeButton}
        onClick={togglePasswordVisibility}
        aria-label={showPassword ? 'Hide password' : 'Show password'}
      >
        <img 
          src={showPassword ? eyeOffIcon : eyeIcon} 
          alt={showPassword ? 'Hide password' : 'Show password'} 
          className={styles.eyeIcon}
        />
      </button>
    </div>
  );
};

export default PasswordField; 