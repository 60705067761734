import React from 'react';
import styles from './Question.module.css';

interface QuestionTextProps {
  text?: string;
}

const defaultProps = {
  text: 'Tell us how often you travel',
};

const QuestionText: React.FC<QuestionTextProps> = ({ 
  text = defaultProps.text 
}) => {
  return (
    <div className={styles.questionText}>
      {text}
    </div>
  );
};

export default QuestionText; 