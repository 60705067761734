import React from 'react';
import styles from './Registration.module.css';

interface PasswordHintProps {
  text?: string;
}

const defaultProps = {
  text: 'Your password must be at least 8 characters including a lower- case letter, an upper- case letter, and a number',
};

const PasswordHint: React.FC<PasswordHintProps> = ({ 
  text = defaultProps.text 
}) => {
  return (
    <div className={styles.passwordHint}>
      {text}
    </div>
  );
};

export default PasswordHint; 