import React from 'react';
import styles from './Privacy.module.css';
import shieldIcon from '../../../../assets/icons/shield-alt.svg';

const ShieldIcon: React.FC = () => {
  return (
    <img 
      src={shieldIcon} 
      alt="Privacy Shield" 
      className={styles.icon} 
      style={{ 
        filter: 'invert(18%) sepia(87%) saturate(6132%) hue-rotate(276deg) brightness(97%) contrast(125%)'
      }} 
    />
  );
};

export default ShieldIcon; 