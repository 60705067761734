import React from 'react';
import styles from './Navigation.module.css';

interface PageTitleProps {
  text?: string;
}

const defaultProps = {
  text: 'What users say about us',
};

const PageTitle: React.FC<PageTitleProps> = ({ text = defaultProps.text }) => {
  return (
    <div className={styles.pageTitle}>
      {text}
    </div>
  );
};

export default PageTitle; 