import React from 'react';
import { TestimonialAvatar, TestimonialQuote, TestimonialAuthor } from '../components/Content';
import eugeneImage from '../../../assets/images/eugene.png';

interface Step3Props {
  onNext: () => void;
  onPrev: () => void;
}

const testimonialText = "Flatsharing helped me rent out my apartment in London fairly quickly and find nice people who aren't afraid to leave it. I think the main value of the bot is the community of people and professionals. Thank you, guys, for a truly useful service";

const Step3: React.FC<Step3Props> = ({ onNext, onPrev }) => {
  return (
    <div className="onboarding-step">
      <div className="step-content">
        <TestimonialAvatar 
          image={eugeneImage} 
        />
        <TestimonialQuote text={testimonialText} />
        <TestimonialAuthor 
          name="Eugene"
          occupation="Staff Software Engineer at Meta"
        />
      </div>
    </div>
  );
};

export default Step3; 