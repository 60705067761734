/**
 * Helper function to determine the redirect link based on token presence and section name
 * @param sectionName - Name of the section (e.g., "Hero", "Community", etc.)
 * @returns The appropriate redirect URL
 */
export const getRedirectLink = (sectionName: string): string => {
  const token = localStorage.getItem("token");
  const userEmailData = JSON.parse(localStorage.getItem("userEmailData") || "{}");
  // If token exists, redirect to browse apartments
  if (token) {
    if (userEmailData.is_accepted) {
      return "/browse";
    } else {
      return "/account/myprofile";
    }
  }
  
  // If no token, redirect to onboarding with UTM parameters
  return `/onboarding?utm_source=main&utm_medium=${sectionName}`;
}; 