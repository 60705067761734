import React from 'react';
import styles from './Privacy.module.css';

interface PrivacyDescriptionProps {
  text?: string;
}

const defaultText = (
  <>
    By registering and proceeding further, you agree to our {' '}
    <span className={styles.link} onClick={() => window.open(process.env.REACT_APP_PRIVACY_POLICY_URL, '_blank')}>
      Privacy Policy
    </span>
    {' '} and {' '}
    <span className={styles.link} onClick={() => window.open(process.env.REACT_APP_TERMS_OF_SERVICE_URL, '_blank')}>
      Terms of Service
    </span>
    .
  </>
);

const PrivacyDescription: React.FC<PrivacyDescriptionProps> = ({ 
  text = defaultText 
}) => {
  return (
    <div className={styles.description}>
      {text}
    </div>
  );
};

export default PrivacyDescription; 