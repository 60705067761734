import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { RootState } from '../../app/redux/store';
import { nextStep, prevStep, goToStep } from '../../app/redux/onboarding/onboardingSlice';
import { clearOnboardingData } from '../../utils/localStorage';
import OnboardingLayout from './components/OnboardingLayout';
import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import Step3 from './steps/Step3';
import Step4 from './steps/Step4';
import Step5 from './steps/Step5';
import Step6 from './steps/Step6';
import Step7 from './steps/Step7';
import Step8 from './steps/Step8';
import Step9 from './steps/Step9';
import Step10 from './steps/Step10';
import Step11 from './steps/Step11';
import Step12 from './steps/Step12';
import Step13 from './steps/Step13';
import Step14 from './steps/Step14';
import Step15 from './steps/Step15';
import Step16 from './steps/Step16';
import Step17 from './steps/Step17';
import { useTracking } from '../../hooks/useTracking';
import './styles.css';

const OnboardingPage: React.FC = () => {
  const { currentStep, totalSteps } = useSelector((state: RootState) => state.onboarding);
  const dispatch = useDispatch();
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
  const hasCleared = useRef(false);
  const [searchParams, setSearchParams] = useSearchParams();


  // Only track when on first step AND searchParams is NOT exactly "step=1"
  // This means we'll track when there are no params or other UTM params
  const shouldTrack = currentStep === 1 && searchParams.toString() !== 'step=1';
  
  // Log tracking decision
  useEffect(() => {
    console.log('OnboardingPage - Tracking decision:', {
      shouldTrack,
      currentStep,
      searchParamsString: searchParams.toString()
    });
  }, [shouldTrack, currentStep, searchParams]);
  
  // Always call hook but only enable tracking when conditions are met
  useTracking({
    saveToOnboarding: shouldTrack,
    redirectToTelegram: false
  });

  // Scroll to top whenever currentStep changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  // Read step from URL when component mounts
  useEffect(() => {
    const stepParam = searchParams.get('step');
    if (stepParam) {
      const parsedStep = parseInt(stepParam, 10);
      if (!isNaN(parsedStep) && parsedStep >= 1 && parsedStep <= totalSteps && parsedStep !== currentStep) {
        // Valid step parameter - update the Redux state
        dispatch(goToStep(parsedStep));
      } else if (!isNaN(parsedStep) && (parsedStep < 1 || parsedStep > totalSteps)) {
        // Invalid step parameter - redirect to step 1
        console.warn(`Invalid step parameter: ${parsedStep}, redirecting to step 1`);
        setSearchParams({ step: '1' });
        dispatch(goToStep(1));
      }
    } else if (currentStep !== 1 || searchParams.toString() !== '') {
      // No step parameter or mismatch - sync with current step
      setSearchParams({ step: currentStep.toString() });
    }
  }, [searchParams, dispatch, totalSteps, currentStep, setSearchParams]);

  // Clear localStorage when first loading the onboarding - use ref to ensure it runs only once
  useEffect(() => {
    if (currentStep === 1 && !hasCleared.current) {
      clearOnboardingData();
      hasCleared.current = true;
      console.log('Onboarding data cleared on initial load');
    }
  }, [currentStep]);

  // Reset nextButtonDisabled state when changing steps
  useEffect(() => {
    // Steps that have togglable next buttons
    const stepsWithTogglableButton = [6, 8];
    
    // If the current step doesn't have a togglable button, enable it
    if (!stepsWithTogglableButton.includes(currentStep)) {
      setNextButtonDisabled(false);
    }
  }, [currentStep]);

  // Handle navigation between steps
  const handleNext = () => {
    if (currentStep < totalSteps) {
      window.scrollTo(0, 0);
      dispatch(nextStep());
      setSearchParams({ step: (currentStep + 1).toString() });
    }
  };

  const handlePrev = () => {
    if (currentStep > 1) {
      window.scrollTo(0, 0);
      dispatch(prevStep());
      setSearchParams({ step: (currentStep - 1).toString() });
    }
  };

  // Update next button disabled state
  const updateNextButtonState = (isEnabled: boolean) => {
    setNextButtonDisabled(!isEnabled);
  };

  // Get page title based on current step
  const getPageTitle = () => {
    switch (currentStep) {
      case 1:
        return ''; // No title for the first step
      case 2:
        return 'What users think about us';
      case 3:
        return 'What users think about us';
      case 4:
        return ''; // No title for step 4 (only back arrow)
      case 5:
        return ''; // No title for step 5 (only back arrow)
      case 6:
        return 'Which locations do you prefer?'; 
      case 7:
        return ''; // No title for step 7 (only back arrow)
      case 8:
        return 'What industry do you work in?'; // No title for step 8 (only back arrow)
      case 9:
        return ''; // No title for step 9 (only back arrow)
      case 10:
        return 'How does it work?'; // Title for step 10
      case 11:
        return ''; // No title for step 11 (only back arrow)
      case 12:
        return 'Last step'; // Title for step 12 (verification step)
      case 13:
        return ''; // No title for step 13 (was step 12)
      case 14:
        return ''; // No title for step 14 (was step 13)
      case 15:
        return ''; // No title for step 15 (was step 14)
      case 16:
        return ''; // No title for step 16 (was step 15)
      case 17:
        return 'Try Flatsharing for 3 days'; // Title for step 17 (was step 16) (the strikethrough is handled in PageNavigation)
      default:
        return 'Onboarding';
    }
  };

  // Render the current step
  const renderCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return <Step1 onNext={handleNext} />;
      case 2:
        return <Step2 onNext={handleNext} onPrev={handlePrev} />;
      case 3:
        return <Step3 onNext={handleNext} onPrev={handlePrev} />;
      case 4:
        return <Step4 onNext={handleNext} onPrev={handlePrev} />;
      case 5:
        return <Step5 onNext={handleNext} onPrev={handlePrev} />;
      case 6:
        return (
          <Step6 
            onNext={handleNext} 
            onPrev={handlePrev} 
            updateNextButtonState={updateNextButtonState} 
          />
        );
      case 7:
        return <Step7 onNext={handleNext} onPrev={handlePrev} />;
      case 8:
        return (
          <Step8 
            onNext={handleNext} 
            onPrev={handlePrev} 
            updateNextButtonState={updateNextButtonState} 
          />
        );
      case 9:
        return <Step9 onNext={handleNext} onPrev={handlePrev} />;
      case 10:
        return <Step10 onNext={handleNext} onPrev={handlePrev} />;
      case 11:
        return <Step11 onNext={handleNext} onPrev={handlePrev} />;
      case 12:
        return <Step12 onNext={handleNext} onPrev={handlePrev} />;
      case 13:
        return <Step13 onNext={handleNext} onPrev={handlePrev} />;
      case 14:
        return <Step14 onNext={handleNext} onPrev={handlePrev} />;
      case 15:
        return <Step15 onNext={handleNext} onPrev={handlePrev} />;
      case 16:
        return <Step16 onNext={handleNext} onPrev={handlePrev} />;
      case 17:
        return <Step17 onNext={handleNext} onPrev={handlePrev} />;
      default:
        return <Step1 onNext={handleNext} />;
    }
  };

  // Determine button label
  const getNextButtonLabel = () => {
    if (currentStep === totalSteps) {
      return 'Finish';
    } else if (currentStep === 11) {
      return "I'm in!";
    }
    return 'Next';
  };

  // Determine if the next button should be shown
  const shouldShowNextButton = () => {
    // Hide next button on steps 4, 5, 8, 12, 13, 14, 15, 16, and 17 as they have their own buttons
    return currentStep !== 4 && currentStep !== 5 && 
           currentStep !== 8 && 
           currentStep !== 12 && currentStep !== 13 && 
           currentStep !== 14 && currentStep !== 15 && 
           currentStep !== 16 && currentStep !== 17;
  };

  // Determine if the back arrow should be shown
  const shouldShowBackArrow = () => {
    // Hide back arrow on step 1, step 15, step 16, and step 17
    return currentStep > 1 && currentStep !== 15 && currentStep !== 16 && currentStep !== 17;
  };

  return (
    <>
      <OnboardingLayout 
        currentStep={currentStep} 
        totalSteps={totalSteps}
        onNext={handleNext}
        onPrev={handlePrev}
        pageTitle={getPageTitle()}
        showBackArrow={shouldShowBackArrow()}
        showNextButton={shouldShowNextButton()}
        nextButtonLabel={getNextButtonLabel()}
        nextButtonDisabled={nextButtonDisabled}
        updateNextButtonState={updateNextButtonState}
      >
        {renderCurrentStep()}
      </OnboardingLayout>
    </>
  );
};

export default OnboardingPage; 