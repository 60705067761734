import React from 'react';
import styles from './Verification.module.css';

interface VerificationDescriptionProps {
  text: string;
}

const VerificationDescription: React.FC<VerificationDescriptionProps> = ({ 
  text 
}) => {
  return (
    <div className={styles.description}>
      {text}
    </div>
  );
};

export default VerificationDescription; 