import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.css';
import { SecondTitle } from '../../shared/secondTitle/SecondTitle';
import { Text } from '../../shared/Text/Text';
import { Button } from '../../shared/button/Button';

interface TelegramLoginFormProps {
  onClose: () => void;
}

const TelegramLoginForm: FC<TelegramLoginFormProps> = ({ onClose }) => {
  const navigate = useNavigate();
  
  // Telegram bot access link
  const telegramBotLink = 'https://t.me/flatsharing_close_bot?start=login_to_web';

  return (
    <div className={styles.telegramLoginInstructions}>
      <SecondTitle mb="10px">Login with Telegram</SecondTitle>
      
      <div className={styles.instructionSteps}>
        <div className={styles.step}>
          <div className={styles.stepNumber}>1</div>
          <div className={styles.stepText}>
            <Text style={{ marginTop: "0", marginBottom: "10px" }}>
              Use this link to go to Telegram and get an access link:
            </Text>
            <a 
              href={telegramBotLink} 
              target="_blank" 
              rel="noopener noreferrer" 
              className={styles.telegramLink}
            >
              <Button $bg $icon>
                Open Telegram Bot
              </Button>
            </a>
            <Text style={{ marginTop: "15px", marginBottom: "10px" }}>
              OR
            </Text>
            <Text style={{ marginBottom: "0" }}>
              Send <span className={styles.command}>/access_web</span> message to <span className={styles.botName}>flatsharing_close_bot</span> in Telegram and get an access link.
            </Text>
          </div>
        </div>
        
        <div className={styles.step}>
          <div className={styles.stepNumber}>2</div>
          <div className={styles.stepText}>
            <Text style={{ marginTop: "0", marginBottom: "0" }}>
              Click the link sent by the bot and enjoy the web version!
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TelegramLoginForm; 