import React, { ReactNode } from 'react';
import styles from './styles.module.css';
import { HeaderBar } from './Header';
import { HeaderNavigation, FooterNavigation } from './Navigation';

interface OnboardingLayoutProps {
  children: ReactNode;
  currentStep: number;
  totalSteps: number;
  onNext: () => void;
  onPrev: () => void;
  pageTitle?: string;
  showBackArrow?: boolean;
  showNextButton?: boolean;
  nextButtonLabel?: string;
  nextButtonDisabled?: boolean;
  updateNextButtonState?: (isEnabled: boolean) => void;
}

const OnboardingLayout: React.FC<OnboardingLayoutProps> = ({
  children,
  currentStep,
  totalSteps,
  onNext,
  onPrev,
  pageTitle,
  showBackArrow = true,
  showNextButton = true,
  nextButtonLabel = 'Next',
  nextButtonDisabled = false,
  updateNextButtonState
}) => {
  // Determine if we're on step 1
  const isStep1 = currentStep === 1;
  
  return (
    <div className={styles.onboardingContainer}>
      <HeaderBar title="Flatsharing" isStep1={isStep1} />
      
      <HeaderNavigation
        title={pageTitle}
        showBackArrow={showBackArrow && currentStep > 1}
        onBack={onPrev}
      />
      
      <div className={styles.onboardingContent}>
        <div className={styles.contentArea}>
          {React.cloneElement(children as React.ReactElement, {
            updateNextButtonState
          })}
        </div>
        
        <div className={styles.footerArea}>
          <FooterNavigation
            showNextButton={showNextButton}
            nextButtonLabel={nextButtonLabel}
            nextButtonDisabled={nextButtonDisabled}
            onNext={onNext}
          />
        </div>
      </div>
    </div>
  );
};

export default OnboardingLayout; 