import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const MetaPixel = () => {
  const location = useLocation();

  useEffect(() => {
    if (typeof window.fbq === "function") {
      window.fbq('track', 'PageView');
    }
  }, [location]);

  return null;
};

export default MetaPixel; 