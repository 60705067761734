import React from 'react';
import styles from './Content.module.css';

interface TestimonialAvatarProps {
  image?: string;
}

const defaultProps = {
  image: 'https://assets.api.uizard.io/api/cdn/stream/a42adccf-ac35-419f-9774-01010354c323.png',
};

const TestimonialAvatar: React.FC<TestimonialAvatarProps> = ({ 
  image = defaultProps.image 
}) => {
  return (
    <div 
      className={styles.testimonialAvatar}
      style={{
        backgroundImage: `url(${image})`,
      }} 
    />
  );
};

export default TestimonialAvatar; 