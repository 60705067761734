import React from 'react';
import styles from './TrialOption.module.css';
import planeIcon from '../../../../assets/icons/plane.svg';

interface PlaneIconProps {
  text?: string;
}

const defaultProps = {
  text: 'Trial upgrade',
};

const PlaneIcon: React.FC<PlaneIconProps> = ({ 
  text = defaultProps.text 
}) => {
  return (
    <div className={styles.planeIcon}>
      <img 
        src={planeIcon} 
        alt="Plane" 
        width="14" 
        height="14"
      />
      <span className={styles.planeText}>{text}</span>
    </div>
  );
};

export default PlaneIcon; 