import React from "react";

import clsx from "./ui/index.module.css";
import { Title } from "../../../shared/title/Title";
import GreyCardWithImage from "../../../shared/greyCard/GreyCard";
import { SecondTitle } from "../../../shared/secondTitle/SecondTitle";
import { Text } from "../../../shared/Text/Text";
import { Button } from "../../../shared/button/Button";
import { useNavigate } from "react-router-dom";
import content from "./content.json";
import { getRedirectLink } from "../../../shared/helpers/getRedirectLink";

const HowItWork = () => {
  const navigate = useNavigate();
  return (
    <div id="margin_top" className={`container ${clsx.wrapper}`}>
      <Title style={{ textAlign: "center" }}>How it works</Title>
      <div className={clsx.cards}>
        {content.map((item) => (
          <div className={clsx.card} key={item.id}>
            <GreyCardWithImage id={clsx.grey_card} src={item.imagePath} loading="lazy">
              {/* If you need text on top of the image, add it here with position: relative, z-index: 1 */}
            </GreyCardWithImage>
            <div>
              <SecondTitle
                id={clsx.card_title}
                style={{ fontFamily: "Roboto Condensed, sans-serif" }}
              >
                {item.title}
              </SecondTitle>
              <Text id={clsx.card_text}>{item.description}</Text>
            </div>
          </div>
        ))}
      </div>
      <div className={clsx.buttons}>
        <Button onClick={() => navigate(getRedirectLink("HowItWork"))} $bg $icon>
          See more
        </Button>
      </div>
    </div>
  );
};

export default HowItWork;
