import React from 'react';
import styles from './Content.module.css';

interface OnboardingDescriptionProps {
  text?: string;
}

const defaultProps = {
  text: 'We\'re very happy to see you here 🙂 You need to answer a few questions and we\'ll give you access to the FlatSharing community. You\'ll be able to rent apartments at 2-3 times cheaper than on Airbnb, essentially at cost from your peers. Plus, you can rent out your space too.',
};

const OnboardingDescription: React.FC<OnboardingDescriptionProps> = ({ 
  text = defaultProps.text 
}) => {
  return (
    <div className={styles.description}>
      {text}
    </div>
  );
};

export default OnboardingDescription; 