import { Title } from "../../shared/title/Title";
import Questions from "../../entities/questions/Questions";
import TravelWithFlatSharing from "../../entities/travelWithFlatSharing/TravelWithFlatSharing";
import styles from "./ui/index.module.css";
import data from "./content.json";
import { useNavigate, useSearchParams } from "react-router-dom";
import Pagination from "../browse/list/Pagination";

export const Blog = () => {
  const [searchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get("page") || "1");
  const navigate = useNavigate();

  const itemsPerPage = 10;

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentItems = data.slice(startIndex, endIndex);

  return (
    <div className="container">
      <Title className={styles.title}>Blogs</Title>
      <div className={`${styles.list}`}>
        {currentItems.map((item: any) => (
          <div
            key={item.id}
            onClick={() => navigate(`/blog/${item.id}/${encodeURIComponent(item.title)}`)}
            className={styles.card}
          >
            <div>
              <h4 className={styles.card_title}>{item.title}</h4>
              <p>{item.secondary_title}</p>
            </div>
            {item.content && item.content.length > 0 && item.content[0].image && (
              <img src={item.content[0].image} alt={item.title} />
            )}
          </div>
        ))}
      </div>
      <Pagination length={data.length} />
      <Questions />
      <TravelWithFlatSharing />
    </div>
  );
};
