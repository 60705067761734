import React from 'react';
import styles from './Question.module.css';
import moneyIcon from '../../../../assets/icons/money-bill-wave.svg';

interface MoneyIconProps {
  IconComponent?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
}

// Default SVG for the money icon
const MoneyIconSVG: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg {...props} viewBox="0 0 640 512">
    <path d="M621.16 54.46C582.37 38.19 543.55 32 504.75 32c-123.17-.01-246.33 62.34-369.5 62.34-30.89 0-61.76-3.92-92.65-13.72-3.47-1.1-6.95-1.62-10.35-1.62C15.04 79 0 92.32 0 110.81v317.26c0 12.63 7.23 24.6 18.84 29.46C57.63 473.81 96.45 480 135.25 480c123.17 0 246.34-62.35 369.51-62.35 30.89 0 61.76 3.92 92.65 13.72 3.47 1.1 6.95 1.62 10.35 1.62 17.21 0 32.25-13.32 32.25-31.81V83.93c-.01-12.64-7.24-24.6-18.85-29.47zM48 132.22c20.12 5.04 41.12 7.57 62.72 8.93C104.84 170.54 79 192.69 48 192.69v-60.47zm0 285v-47.78c34.37 0 62.18 27.27 63.71 61.4-22.53-1.81-43.59-6.31-63.71-13.62zM320 352c-44.19 0-80-42.99-80-96 0-53.02 35.82-96 80-96s80 42.98 80 96c0 53.03-35.83 96-80 96zm272 27.78c-17.52-4.39-35.71-6.85-54.32-8.44 5.87-26.08 27.5-45.88 54.32-49.28v57.72zm0-236.11c-30.89-3.91-54.86-29.7-55.81-61.55 19.54 2.17 38.09 6.23 55.81 12.66v48.89z">
    </path>
  </svg>
);

const MoneyIcon: React.FC<MoneyIconProps> = ({ IconComponent }) => {
  const [iconError, setIconError] = React.useState(false);

  const handleError = () => {
    setIconError(true);
  };

  return (
    <div className={styles.questionIconContainer}>
      {IconComponent ? (
        <IconComponent className={styles.questionIcon} />
      ) : iconError ? (
        <MoneyIconSVG className={styles.questionIcon} />
      ) : (
        <img 
          src={moneyIcon} 
          alt="Money" 
          className={styles.questionIcon} 
          onError={handleError}
        />
      )}
    </div>
  );
};

export default MoneyIcon; 