import React from 'react';
import styles from './Navigation.module.css';
import arrowLeft from '../../../../assets/icons/arrow-left.svg';

interface BackArrowProps {
  onClick?: () => void;
  IconComponent?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
}

// Fallback SVG in case the imported file is not found
const ArrowLeftSVG: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg {...props} viewBox="0 0 448 512">
    <path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z" />
  </svg>
);

const BackArrow: React.FC<BackArrowProps> = ({ onClick, IconComponent }) => {
  const [iconError, setIconError] = React.useState(false);

  const handleError = () => {
    setIconError(true);
  };

  return (
    <div className={styles.backArrowContainer} onClick={onClick}>
      {IconComponent ? (
        <IconComponent className={styles.backArrow} />
      ) : iconError ? (
        <ArrowLeftSVG className={styles.backArrow} />
      ) : (
        <img 
          src={arrowLeft} 
          alt="Back" 
          className={styles.backArrow} 
          onError={handleError}
        />
      )}
    </div>
  );
};

export default BackArrow; 