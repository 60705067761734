import { FC } from "react";
import { Button } from "../../shared/button/Button";
import { Text } from "../../shared/Text/Text";
import { Title } from "../../shared/title/Title";
import styles from "./ui/index.module.css";
import { ModalProps } from "../auth/login/model/types";

interface Props extends ModalProps {
  handleSave: (archive: boolean) => void;
}

const AddAdvertPopUp: FC<Props> = ({ handleSave }) => {
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={styles.content_wrapper}
    >
      <Title $fz="40px">Add contacts for communication</Title>
      <div style={{ marginTop: "18px" }}>
        <Text mt="18px">
          There are no contact details but email in the My Profile section that tenants
          can use to contact you. <br /> Enter the details to your profile.
        </Text>
      </div>
      <div className={styles.buttons}>
        {/* <Button onClick={() => handleSave(true)} $border>
          Archive the ad
        </Button> */}
        <Button onClick={() => window.location.href = '/account/myprofile'} $fz="24px" $bg $icon $border>
          Add contacts
        </Button>
      </div>
    </div>
  );
};

export default AddAdvertPopUp;
