import React from 'react';

interface PurpleIconProps {
  src: string;
  alt: string;
}

const PurpleIcon: React.FC<PurpleIconProps> = ({ src, alt }) => {
  return (
    <img 
      src={src} 
      alt={alt} 
      style={{ 
        width: '24px', 
        height: '24px',
        filter: 'invert(18%) sepia(87%) saturate(6132%) hue-rotate(276deg) brightness(97%) contrast(125%)'
      }} 
    />
  );
};

export default PurpleIcon; 