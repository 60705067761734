import React from 'react';

interface TagProps {
  label: string;
  selected: boolean;
  onClick: () => void;
}

const defaultProps = {
  label: 'Technology',
};

const Tag: React.FC<TagProps> = ({ 
  label = defaultProps.label, 
  selected, 
  onClick 
}) => {
  const styles = {
    tag: {
      cursor: 'pointer',
      height: '44px',
      padding: '0px 20px',
      border: '0',
      boxSizing: 'border-box' as const,
      borderRadius: '10px',
      backgroundColor: '#8a2be2',
      color: '#ffffff',
      fontSize: '16px',
      fontFamily: 'Lexend Deca',
      lineHeight: '44px',
      display: 'inline-block',
      transition: 'all 0.2s ease',
      outline: 'none',
      textAlign: 'center' as const,
      fontWeight: 500,
      opacity: selected ? 1 : 0.7,
      transform: selected ? 'scale(1.05)' : 'scale(1)',
      boxShadow: selected ? '0 3px 8px rgba(138, 43, 226, 0.4)' : 'none',
      whiteSpace: 'nowrap' as const,
      marginBottom: '0',
    },
  };

  return (
    <button 
      style={styles.tag} 
      onClick={onClick}
      aria-pressed={selected}
    >
      {label}
    </button>
  );
};

export default Tag; 