import styled from "styled-components";
import React from "react";
import { GreyCardProps } from "./model/types";

export const GreyCard = styled.section<GreyCardProps>`
  background-color: #f5f6f6;
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "684px")};
  display: flex;
  flex-direction: ${(props) => (!props.$column ? "column" : "row")};
  justify-content: ${(props) => (props.$left ? "flex-start" : "center")};
  align-items: ${(props) => (props.$left ? "flex-start" : "center")};
  border-radius: 26px;
  padding: ${(props) => (props.$hero ? "25px" : "20px")};
  gap: ${(props) => (props.$hero ? "20px" : "")};
  position: relative;
  overflow: hidden;
`;

export const BackgroundImage = styled.img.attrs<{ loading?: string }>(props => ({
  loading: props.loading || 'lazy',
}))`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
`;

interface GreyCardWithImageProps extends GreyCardProps {
  children?: React.ReactNode;
  id?: string;
  loading?: string;
}

const GreyCardWithImage = ({ src, children, id, loading, ...props }: GreyCardWithImageProps) => {
  return (
    <GreyCard id={id} {...props}>
      {src && <BackgroundImage src={src} alt="background" loading={loading} />}
      {children}
    </GreyCard>
  );
};

export default GreyCardWithImage;
