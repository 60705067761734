import React from 'react';
import styles from './Trial.module.css';

interface NumberCardProps {
  number: number;
}

const NumberCard: React.FC<NumberCardProps> = ({ number }) => {
  return (
    <div className={styles.numberCard}>
      <span className={styles.number}>{number}</span>
    </div>
  );
};

export default NumberCard; 