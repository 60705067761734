import React from 'react';
import styles from './Content.module.css';

interface TestimonialQuoteProps {
  text?: string;
}

const defaultProps = {
  text: '"I\'ve experienced FlatSharing in Berlin, and several times in Amsterdam and London. I don\'t think I would have gone if this option didn\'t exist. It\'s really awesome! It\'s especially fun to discover the number of unexpected mutual friends with hosts on social media."',
};

const TestimonialQuote: React.FC<TestimonialQuoteProps> = ({ 
  text = defaultProps.text 
}) => {
  return (
    <div className={styles.testimonialQuote}>
      {text}
    </div>
  );
};

export default TestimonialQuote; 