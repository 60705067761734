import React, { ReactNode } from 'react';
import styles from './HowItWorks.module.css';

interface FeatureProps {
  icon: ReactNode;
  title: string;
  description?: string;
}

const Feature: React.FC<FeatureProps> = ({ icon, title, description }) => {
  return (
    <div className={styles.featureContainer}>
      <div className={styles.featureHeader}>
        <div className={styles.featureIcon}>
          {icon}
        </div>
        <div className={styles.featureTitle}>
          {title}
        </div>
      </div>
      {description && (
        <div className={styles.featureDescription}>
          {description}
        </div>
      )}
    </div>
  );
};

export default Feature; 