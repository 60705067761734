import React, { useState } from "react";
import clsx from "./ui/index.module.css";
import { Title } from "../../shared/title/Title";
import { SecondTitle } from "../../shared/secondTitle/SecondTitle";
import { Text } from "../../shared/Text/Text";
import { ReactComponent as Arrow } from "../../assets/icons/arrow.svg";

const questionsAndAnswers = [
  {
    question: "How do I join the community?",
    answer: "Fill out a short form on our website, we'll review your application within a day, and grant you access to the portal where hosts post their listings. The application takes no more than 3 minutes, and the benefits are invaluable."
  },
  {
    question: "What kind of social activities do you have?",
    answer: "We hold pleasant gatherings during handovers, regular meetings where we discuss tax regimes, travel and other different things. We also support offline meetings that we call 'random coffee', matching people from the same cities."
  },
  {
    question: "How much can I save per year with Flatsharing?",
    answer: "If you rent an apartment through FlatSharing in Europe for 3-5 weeks a year, you could save $2,000 to $5,000 over year."
  },
  {
    question: "Are there possibilities of swapping, not subletting?",
    answer: "Yes, apartment exchanges do occur on the service, and this happens quite frequently, especially across Europe. Users are able to coordinate and swap their living spaces with one another, whether it's for a short-term vacation or a longer-term arrangement."
  }
];

const Questions = () => {
  const [opened, setOpened] = useState<number | null>(null);

  const handleToggle = (index: number) => {
    setOpened(opened === index ? null : index);
  };

  return (
    <div id="margin_top" className={`${clsx.questions} container`}>
      <div className={clsx.titles}>
        <Title>Questions?</Title>
        <Title>We’re here to help</Title>
      </div>
      <div className={clsx.wrapper}>
        {questionsAndAnswers.map((item, index) => (
          <div
            key={index}
            className={`${clsx.accordion} ${opened === index ? clsx.open : ""}`}
            style={{
              maxHeight: "auto",
            }}
          >
            <div className={clsx.header} onClick={() => handleToggle(index)}
                 style={{
                   display: "flex",
                   flexGrow: 1,
                   justifyContent: "space-between",
                   alignItems: "center",
                 }}>
              <SecondTitle style={{
                   display: "flex",
                 }}>{item.question}</SecondTitle>
              <Arrow
                className={`${clsx.arrow} ${
                  opened === index ? clsx.rotated : ""
                }`}
              />
            </div>
            <div
              className={clsx.content}
              style={{
                maxHeight: opened === index ? "200px" : "0",
                overflow: "hidden",
                transition: "max-height 0.3s ease, opacity 0.3s ease",
              }}
            >
              <Text>{item.answer}</Text>
            </div>
          </div>
        ))}
        <div style={{ color: "grey", fontSize: "small", marginTop: "20px" }}>
          If you did not find the answer to your question in the list, write to us at <a href="mailto:contact@flatsharingcommunity.com">contact@flatsharingcommunity.com</a>
        </div>
      </div>
    </div>
  );
};

export default Questions;
