import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/redux/store';
import { 
  Title, 
  SubtitleText, 
  Button, 
  LoginLink,
  PasswordField,
  PasswordHint
} from '../components/Registration';
import useOnboardingData from '../../../hooks/useOnboardingData';
import { ErrorPopup, SuccessPopup } from '../../../shared/ui/Popup';
import Spinner from '../../../shared/ui/Spinner';
import { 
  useRegisterMutation, 
  useLoginMutation, 
  useVerifyAccountMutation,
  useEditUserInfoMutation
} from '../../../app/redux/auth/authApi';

interface Step14Props {
  onNext: () => void;
  onPrev: () => void;
}

// Password validation regex patterns
const LOWERCASE_REGEX = /[a-z]/;
const UPPERCASE_REGEX = /[A-Z]/;
const NUMBER_REGEX = /[0-9]/;
const MIN_LENGTH = 8;

const Step14: React.FC<Step14Props> = ({ onNext, onPrev }) => {
  // Use our custom hook for data management
  const { data: password, setData: setPassword, handleNext: handleNextWithSave } = 
    useOnboardingData<string>('', 'password');
    
  // Get email and all onboarding data from Redux store
  const email = useSelector((state: RootState) => state.onboarding.data.email);
  const onboardingData = useSelector((state: RootState) => state.onboarding.data);

  // API mutation hooks
  const [register, { isLoading: isRegistering }] = useRegisterMutation();
  const [login, { isLoading: isLoggingIn }] = useLoginMutation();
  const [verifyAccount, { isLoading: isVerifying }] = useVerifyAccountMutation();
  const [editUserInfo, { isLoading: isEditingUserInfo }] = useEditUserInfoMutation();
  
  // State for error and success handling
  const [error, setError] = useState<string | null>(null);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('Successfully registered!');
  
  // Loading state for button
  const isLoading = isRegistering || isLoggingIn || isVerifying || isEditingUserInfo;

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  // Validate password meets all requirements
  const isValidPassword = (password: string): boolean => {
    return (
      password.length >= MIN_LENGTH &&
      LOWERCASE_REGEX.test(password) &&
      UPPERCASE_REGEX.test(password) &&
      NUMBER_REGEX.test(password)
    );
  };

  const handleSubmit = async () => {
    // Only proceed if password is valid
    if (!isValidPassword(password)) return;
    
    try {
      let isNewUser = true;
      
      // Step 1: Register the user
      try {
        const userData = { email, password };
        const registerResponse = await register({ user: userData }).unwrap();
        console.log('Registration successful:', registerResponse);
        setSuccessMessage('Account created successfully! Verification email sent.');
      } catch (registerError: any) {
        // Check if this is a "user already exists" error
        const errorData = registerError?.data;
        if (errorData && 
            (errorData.detail === 'REGISTER_USER_ALREADY_EXISTS' || 
             errorData.detail?.includes('already exists') ||
             errorData.detail?.includes('already registered'))) {
          // Show error for user already exists
          console.error('Registration failed - user already exists:', registerError);
          const errorMessage = createDetailedErrorMessage('Registration', registerError);
          setError(errorMessage);
          setShowError(true);
          return; // Stop the process here
        } else {
          // For other registration errors, bubble up the error
          throw registerError;
        }
      }
      
      // Step 2: Login the user to get token
      const loginData = new FormData();
      loginData.append("username", email);
      loginData.append("password", password);
      
      const loginResponse = await login({ 
        user: loginData
      }).unwrap();
      
      // Store token in localStorage
      if (loginResponse.access_token) {
        localStorage.setItem('token', loginResponse.access_token);
        console.log('Login successful, token stored');
      }
      
      // Step 3: Verify the account (only for new users, and it's ok if it fails)
      if (isNewUser) {
        // Fire and forget - don't await the verification request
        verifyAccount({ 
          data: { email }
        })
          .then(() => console.log('Verification email sent'))
          .catch(verifyError => console.log('Verification request failed, but proceeding', verifyError));
      }

      // Step 4: Update user info with data collected during onboarding
      try {
        const userInfoData = {
          description: Array.isArray(onboardingData.industries) ? onboardingData.industries.join(', ') : (onboardingData.industries || ''),
          where_to_rent: Array.isArray(onboardingData.locations) ? onboardingData.locations.join(', ') : (onboardingData.locations || ''),
          linkedin: onboardingData.socialLink || ''
        };
        
        await editUserInfo({ user: userInfoData }).unwrap();
        console.log('User info updated with onboarding data:', userInfoData);
      } catch (editError) {
        // Log error but don't stop the flow
        console.error('Failed to update user info, but proceeding:', editError);
      }
      
      // Show success message
      setShowSuccess(true);
      
      // Save user data and move to next step after a short delay
      localStorage.setItem('userData', JSON.stringify({ email, password }));
      
      // Call the next step function immediately without delay
      handleNextWithSave(onNext);
      
    } catch (err: any) {
      // Determine which step failed
      let stepName = 'Authentication';
      
      // Check for specific error messages to determine the failed step
      if (err?.data?.detail?.includes('REGISTER_') || isRegistering) {
        stepName = 'Registration';
      } else if (err?.status === 422 || err?.data?.detail?.includes('login') || isLoggingIn) {
        stepName = 'Login';
      } else if (err?.data?.detail?.includes('verify') || isVerifying) {
        stepName = 'Verification';
      }
      
      // Format the error message
      const errorMessage = createDetailedErrorMessage(stepName, err);
      
      console.error(`${stepName} failed:`, err);
      setError(errorMessage);
      setShowError(true);
    }
  };
  
  /**
   * Creates a detailed error message with formatted JSON for better readability
   */
  const createDetailedErrorMessage = (stepName: string, error: any): string => {
    const statusCode = error?.status || 'Unknown';
    let errorDetails = '';
    
    try {
      // Try to extract and format error details
      if (error?.data) {
        if (typeof error.data === 'object') {
          errorDetails = JSON.stringify(error.data, null, 2);
        } else {
          errorDetails = String(error.data);
        }
      } else if (error?.message) {
        errorDetails = error.message;
      } else {
        errorDetails = 'No error details available';
      }
    } catch (e) {
      errorDetails = 'Could not parse error details';
    }
    
    return `${stepName} failed, code ${statusCode}\n\nDetails:\n${errorDetails}`;
  };
  
  // Handle closing the error popup
  const handleCloseError = () => {
    setShowError(false);
    setError(null);
  };
  
  // Handle closing the success popup
  const handleCloseSuccess = () => {
    setShowSuccess(false);
  };

  return (
    <div className="onboarding-step">
      <div className="step-content" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Title text="Create your FlatSharing account" />
        <SubtitleText text="Save your progress in seconds" />
        
        <PasswordField 
          text="Password" 
          value={password}
          onChange={handlePasswordChange}
        />
        
        <PasswordHint text="Your password must be at least 8 characters including a lower- case letter, an upper- case letter, and a number" />
        
        <Button 
          label={isLoading ? "Processing..." : "Finish Sign up"}
          onClick={handleSubmit}
          disabled={!isValidPassword(password) || isLoading}
        />
        
        <LoginLink />
        
        {/* Loading Spinner */}
        {isLoading && <Spinner overlay={true} />}
        
        {/* Error Popup */}
        <ErrorPopup
          show={showError}
          onHide={handleCloseError}
          message={error || 'An error occurred'}
        />
        
        {/* Success Popup */}
        <SuccessPopup
          show={showSuccess}
          onHide={handleCloseSuccess}
          message={successMessage}
        />
      </div>
    </div>
  );
};

export default Step14; 