import React from 'react';
import styles from './Navigation.module.css';

interface StrikeThroughTitleProps {
  text: string;
  strikeText: string;
  replacementText: string;
}

const StrikeThroughTitle: React.FC<StrikeThroughTitleProps> = ({ text, strikeText, replacementText }) => {
  // Split the text based on the strike text
  const parts = text.split(strikeText);
  
  if (parts.length !== 2) {
    // If the text doesn't contain the strike text, just render the whole text
    return <div className={styles.pageTitle}>{text}</div>;
  }
  
  return (
    <div className={styles.pageTitle}>
      {parts[0]}
      <span style={{ textDecoration: 'line-through' }}>{strikeText}</span>
      {' '}{replacementText}{' '}
      {parts[1]}
    </div>
  );
};

export default StrikeThroughTitle; 