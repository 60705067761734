import React from 'react';
import styles from './Registration.module.css';

interface TitleProps {
  text?: string;
}

const defaultProps = {
  text: 'Create your FlatSharing account',
};

const Title: React.FC<TitleProps> = ({ 
  text = defaultProps.text 
}) => {
  return (
    <h1 className={styles.title}>
      {text}
    </h1>
  );
};

export default Title; 