import React from 'react';
import Tag from './Tag';

interface TagCloudProps {
  tags: string[];
  selectedTags: string[];
  onTagClick: (tag: string) => void;
  maxSelections: number;
}

const defaultProps = {
  maxSelections: 3,
};

const TagCloud: React.FC<TagCloudProps> = ({ 
  tags,
  selectedTags,
  onTagClick,
  maxSelections = defaultProps.maxSelections
}) => {
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column' as const,
      alignItems: 'center',
      gap: '12px',
      maxWidth: '800px',
      margin: '0 auto',
      padding: '0',
    },
    row: {
      display: 'flex',
      justifyContent: 'center',
      gap: '12px',
      width: '100%',
      flexWrap: 'wrap' as const,
      marginBottom: '4px',
    }
  };

  // Function to create rows with specific number of tags
  const createRows = () => {
    const rows = [];
    let currentIndex = 0;

    // First row - 2 tags
    rows.push(
      <div key="row-1" style={styles.row}>
        {tags.slice(currentIndex, currentIndex + 2).map(tag => (
          <Tag
            key={tag}
            label={tag}
            selected={selectedTags.includes(tag)}
            onClick={() => handleTagClick(tag)}
          />
        ))}
      </div>
    );
    currentIndex += 2;

    // Second row - 2 tags
    rows.push(
      <div key="row-2" style={styles.row}>
        {tags.slice(currentIndex, currentIndex + 2).map(tag => (
          <Tag
            key={tag}
            label={tag}
            selected={selectedTags.includes(tag)}
            onClick={() => handleTagClick(tag)}
          />
        ))}
      </div>
    );
    currentIndex += 2;

    // Remaining rows - 3 tags each
    for (let i = 3; i <= 6; i++) {
      rows.push(
        <div key={`row-${i}`} style={styles.row}>
          {tags.slice(currentIndex, currentIndex + 3).map(tag => (
            <Tag
              key={tag}
              label={tag}
              selected={selectedTags.includes(tag)}
              onClick={() => handleTagClick(tag)}
            />
          ))}
        </div>
      );
      currentIndex += 3;
    }

    return rows;
  };

  const handleTagClick = (tag: string) => {
    // If the tag is already selected, allow deselection
    if (selectedTags.includes(tag)) {
      onTagClick(tag);
      return;
    }
    
    // Otherwise, only allow selection if under max limit
    if (selectedTags.length < maxSelections) {
      onTagClick(tag);
    }
  };

  return (
    <div style={styles.container}>
      {createRows()}
    </div>
  );
};

export default TagCloud; 