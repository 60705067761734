import React from "react";
import { Title } from "../../../shared/title/Title";
import { Text } from "../../../shared/Text/Text";
import GreyCardWithImage from "../../../shared/greyCard/GreyCard";
import { AddressGreyWrapper } from "../../../shared/addressGreyWrapperProps/AddressGreyWrapperProps";
import clsx from "./ui/index.module.css";
import { Button } from "../../../shared/button/Button";
import { useNavigate } from "react-router-dom";
import { getRedirectLink } from "../../../shared/helpers/getRedirectLink";

const Travel = () => {
  const navigate = useNavigate();

  return (
    <div id="margin_top">
      <div className="container">
        <div className={clsx.travel_titles}>
          <Title>Cozy, spacious apartments that are 2-4 times cheaper than a claustrophobic hotel room</Title>
          <Text>
          
          </Text>
        </div>
        <div className={clsx.card}>
          <GreyCardWithImage id={clsx.grey_card} src="/apartments/apartments_1.jpg" loading="lazy" $top $left>
            <AddressGreyWrapper>
              <Text>Sublet in Barcelona with Flatsharing</Text>
              <Text>70$ a day</Text>
            </AddressGreyWrapper>
          </GreyCardWithImage>
          <GreyCardWithImage id={clsx.grey_card} src="/apartments/apartments_2.jpg" loading="lazy" $top $left>
            <AddressGreyWrapper style={{ backgroundColor: "#282828D6" }}>
              <Text style={{ color: "white" }}>Hotel in Barcelona</Text>
              <Text style={{ color: "white" }}>150$ a day</Text>
            </AddressGreyWrapper>
          </GreyCardWithImage>
        </div>
        <Button
          onClick={() => navigate(getRedirectLink("Travel"))}
          style={{ margin: "40px auto" }}
          $bg
          $icon
        >
          More about cost
        </Button>
      </div>
    </div>
  );
};

export default Travel;
