import { useNavigate } from "react-router-dom";
import { useGetUserEmailQuery, useGetUserQuery } from "../../redux/auth/authApi";
import { useModal } from "./useModal";

export const useRestrictedNavigation = () => {
  const navigate = useNavigate();
  const { data: userInfoResponse } = useGetUserQuery({});
  const { data: userResponse, refetch } = useGetUserEmailQuery({});
  const { openModal } = useModal();

  const handleNavigationToRestrictedContent = async (targetPath: string) => {
    const token = localStorage.getItem("token");
    const step = userInfoResponse?.onboarding_step || localStorage.getItem("step");
    const allowedUnacceptedPaths = ["/account/myprofile", "/account/subscription"];

    if (token) {
      refetch();
      if (userResponse?.is_accepted) {
        const now = new Date();
        const premiumUntil = userResponse?.premium_until ? new Date(userResponse.premium_until) : null;
        
        if (!premiumUntil || premiumUntil < now) {
          navigate('/account/subscription');
        } else {
          navigate(targetPath);
        }
      } else if (!userResponse?.is_accepted && !allowedUnacceptedPaths.includes(targetPath)) {
        navigate(`/account/myprofile`);
      } else {
        navigate(targetPath);
      }
    } else {
      openModal();
    }
  };

  return { handleNavigationToRestrictedContent };
}; 