import React, { ReactNode } from 'react';
import styles from './Privacy.module.css';

interface IconCardProps {
  children: ReactNode;
}

const IconCard: React.FC<IconCardProps> = ({ children }) => {
  return (
    <div className={styles.iconCard}>
      {children}
    </div>
  );
};

export default IconCard; 