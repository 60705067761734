import Navbar from "./entities/nav/desctop/Navbar";
import MainRoutes from "./app/routes/MainRoutes";
import Footer from "./entities/footer/Footer";
import { useLocation } from "react-router-dom";
import GoogleAnalytics from "./app/googleAnalytics/GoogleAnalytics";
import MetaPixel from "./app/metaPixel/MetaPixel";

const App = () => {
  const location = useLocation();

  const hideNavAndFooter =
    location.pathname === "/login" ||
    location.pathname.startsWith("/password-recovery") ||
    location.pathname.startsWith("/onboarding") ||
    location.pathname.startsWith("/email_verification");

  return (
    <div className="app">
      <GoogleAnalytics />
      <MetaPixel />
      {!hideNavAndFooter && <Navbar />}
      <MainRoutes />
      {!hideNavAndFooter && <Footer />}
    </div>
  );
};

export default App;
