import React from 'react';
import { 
  InputField, 
  Button 
} from '../components/Registration';
import VerificationDescription from '../components/Verification/VerificationDescription';
import useOnboardingData from '../../../hooks/useOnboardingData';

interface Step12Props {
  onNext: () => void;
  onPrev: () => void;
}

const Step12: React.FC<Step12Props> = ({ onNext, onPrev }) => {
  // Use our custom hook for data management
  const { data: socialLink, setData: setSocialLink, handleNext: handleNextWithSave } = 
    useOnboardingData<string>('', 'socialLink');

  const handleLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSocialLink(e.target.value);
  };

  // Basic URL validation
  const isValidUrl = (url: string): boolean => {
    if (!url) return false;
    
    // Allow simple strings for more flexible input
    if (url.length < 3) return false;
    
    // If it's a URL, do a basic check
    if (url.includes('http') || url.includes('www')) {
      try {
        new URL(url.startsWith('http') ? url : `https://${url}`);
        return true;
      } catch (e) {
        return false;
      }
    }
    
    // Also accept profile names/handles
    return true;
  };

  const handleSubmit = () => {
    // Save social link and move to next step
    handleNextWithSave(onNext);
  };

  return (
    <div className="onboarding-step">
      <div className="step-content" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <VerificationDescription text="One last thing: Please attach a link to your Instagram or LinkedIn so we can verify your identity." />
        
        <InputField 
          text="https://linkedin.com/in/yourname" 
          type="text"
          value={socialLink}
          onChange={handleLinkChange}
        />
        
        <Button 
          label="Next" 
          onClick={handleSubmit}
          disabled={!isValidUrl(socialLink)}
        />
      </div>
    </div>
  );
};

export default Step12;
