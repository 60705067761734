import React from 'react';
import styles from './Trial.module.css';

interface TrialTitleProps {
  text: string;
}

const defaultProps = {
  text: "We've upgraded your FlatSharing trial",
};

const TrialTitle: React.FC<TrialTitleProps> = ({ 
  text = defaultProps.text 
}) => {
  return (
    <div className={styles.title}>
      {text}
    </div>
  );
};

export default TrialTitle; 