import { ReactNode, useEffect, useState, useCallback } from "react";
import { Modal } from "../../../shared/modal/portal";

export const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  // Use useCallback to prevent multiple renders and unnecessary state changes
  const openModal = useCallback(() => {
    // Prevent opening if already open or in the process of closing
    if (isOpen || isClosing) return;
    
    setIsOpen(true);
    // Slight delay to allow the component to mount before showing animation
    setTimeout(() => setIsVisible(true), 20);
  }, [isOpen, isClosing]);

  const closeModal = useCallback(() => {
    // Prevent double closing
    if (isClosing) return;
    
    setIsClosing(true);
    setIsVisible(false);
    
    // Wait for the animation to finish before unmounting
    setTimeout(() => {
      setIsOpen(false);
      setIsClosing(false);
    }, 350); // Slightly longer than CSS transition for safety
  }, [isClosing]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  const ModalComponent = useCallback(({ children }: { children: ReactNode }) => {
    if (!isOpen) return null;

    return (
      <Modal>
        <div
          className={`modal ${isVisible ? "modal_active" : ""}`}
          onClick={(e) => {
            // Only close if clicking directly on the backdrop, not on child elements
            if (e.target === e.currentTarget) {
              e.stopPropagation();
              closeModal();
            }
          }}
          style={{ zIndex: 999 }}
        >
          {children}
        </div>
      </Modal>
    );
  }, [isOpen, isVisible, closeModal]);

  return {
    isOpen: isVisible, // Export the visible state
    openModal,
    closeModal,
    ModalComponent,
  };
};
