import React from 'react';
import { Header, HeaderIcon, HeaderText } from './index';
import styles from './HeaderBar.module.css';
import { Link } from 'react-router-dom';

interface HeaderBarProps {
  title?: string;
  isStep1?: boolean;
}

const HeaderBar: React.FC<HeaderBarProps> = ({ 
  title = 'Flatsharing',
  isStep1 = false
}) => {
  const HeaderContent = () => (
    <div className={styles.headerContent}>
      <HeaderIcon />
      <HeaderText text={title} />
    </div>
  );

  return (
    <Header>
      {isStep1 ? (
        <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
          <HeaderContent />
        </Link>
      ) : (
        <HeaderContent />
      )}
    </Header>
  );
};

export default HeaderBar; 