import { FC } from 'react';
import styles from './styles.module.css';
import { useNavigate } from 'react-router-dom';
import Login from '../../entities/auth/login/Login';

const LoginPage: FC = () => {
  const navigate = useNavigate();
  
  const handleCloseModal = () => {
    navigate('/');
  };
  
  return (
    <div className={styles.loginPage}>
      <div className={styles.loginContainer}>
        <Login 
          isOpen={true}
          openModal={() => {}}
          closeModal={handleCloseModal}
          isForgot={false}
          isAccess={false}
          isPage={true}
        />
      </div>
    </div>
  );
};

export default LoginPage; 