import React from 'react';
import { 
  IconCard, 
  ShieldIcon, 
  PrivacyTitle, 
  PrivacyDescription
} from '../components/Privacy';

interface Step11Props {
  onNext: () => void;
  onPrev: () => void;
}

const Step11: React.FC<Step11Props> = ({ onNext, onPrev }) => {
  return (
    <div className="onboarding-step">
      <div className="step-content">
        <div className="privacy-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <IconCard>
            <ShieldIcon />
          </IconCard>
          
          <PrivacyTitle text="Privacy First" />
          
          <PrivacyDescription />
        </div>
      </div>
    </div>
  );
};

export default Step11; 