import { FC, useEffect, useState } from 'react';
import styles from './ErrorPopup.module.css';

interface ErrorPopupProps {
  show: boolean;
  onHide: () => void;
  message?: string;
}

export const ErrorPopup: FC<ErrorPopupProps> = ({ 
  show, 
  onHide, 
  message = 'An error occurred' 
}) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (show) {
      setIsVisible(true);
      setTimeout(() => {
        setIsVisible(false);
        setTimeout(() => {
          onHide();
        }, 300);
      }, 10000); // Show error for longer (10 seconds) to allow reading details
    }
  }, [show, onHide]);

  if (!show) return null;

  // Format the message for better readability
  const formatMessage = (msg: string) => {
    return msg
      .replace(/\\n/g, '\n')
      .replace(/\\"/g, '"')
      .replace(/\\t/g, '  ');
  };

  return (
    <div className={`${styles.error_popup} ${!isVisible ? styles.hide : ''}`}>
      <pre className={styles.error_content}>{formatMessage(message)}</pre>
    </div>
  );
}; 