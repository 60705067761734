import clsx from "./ui/index.module.css";

import accanture from "../../../assets/png/accanture.png";
import amazon from "../../../assets/png/amazon.png";
import booking from "../../../assets/png/booking.png";
import meta from "../../../assets/png/meta.png";
import google from "../../../assets/png/google.png";
import jet from "../../../assets/png/jet.png";
import Marquee from "react-fast-marquee";

const images = [meta, booking, accanture, amazon, google, jet];

const CommunityWidgets = () => {
  return (
    <div className={clsx.bg}>
      <Marquee
        autoFill
        speed={20}
        delay={0}
        loop={0}
        gradient={true}
        gradientColor="#f5f6f6"
        gradientWidth={100}
        pauseOnHover={false}
        className={`${clsx.images}`}
      >
        {images.map((item, index) => (
          <img 
            key={index}
            className={clsx.image} 
            src={item} 
            alt={`partner-${index}`} 
            loading="eager"
            style={{ marginRight: '40px' }} 
          />
        ))}
      </Marquee>
    </div>
  );
};

export default CommunityWidgets;
