import React from 'react';
import styles from './Trial.module.css';

interface TrialDescriptionProps {
  text?: string;
}

const defaultProps = {
  text: "Now you can enjoy all the perks of FlatSharing for 7 days",
};

const TrialDescription: React.FC<TrialDescriptionProps> = ({ 
  text = defaultProps.text 
}) => {
  return (
    <div className={styles.description}>
      {text}
    </div>
  );
};

export default TrialDescription; 