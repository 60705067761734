import React from "react";
import Spinner from "../../shared/ui/Spinner";
import { useTracking } from "../../hooks/useTracking";
import styles from "./ui/index.module.css";

const RedirectToTg: React.FC = () => {
  // Always redirect to Telegram
  const { isLoading } = useTracking({
    saveToOnboarding: false,
    redirectToTelegram: true
  });

  return (
    <div className={styles.container}>
      <div className={styles.spinnerWrapper}>
        <Spinner />
        <p>Redirecting to Telegram...</p>
      </div>
    </div>
  );
};

export default RedirectToTg; 