import { FC, useState, useEffect } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import styles from './styles.module.css';
import TelegramLoginForm from './TelegramLoginForm';
import { ErrorPopup } from '../../shared/ui/Popup/ErrorPopup';
import { useTelegramCodeCheckMutation, useGetUserQuery, useGetUserEmailQuery } from '../../app/redux/auth/authApi';
import Spinner from '../../shared/ui/Spinner';

const TelegramLoginPage: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  
  const [telegramCodeCheck] = useTelegramCodeCheckMutation();
  const { refetch: refetchUserInfo } = useGetUserQuery({}, { skip: true });
  const { refetch: refetchUserEmail } = useGetUserEmailQuery({}, { skip: true });
  
  const handleCloseModal = () => {
    navigate('/');
  };
  
  const handleErrorHide = () => {
    setShowError(false);
  };
  
  // Check for access parameters in URL and process them
  useEffect(() => {
    const processUrlParams = async () => {
      const tgId = searchParams.get('tg_id');
      const tgUsername = searchParams.get('tg_username');
      const accessCode = searchParams.get('access_code');
      
      // Only proceed if we have an access code and either tg_id or username
      if (accessCode && (tgId || tgUsername)) {
        setIsProcessing(true);
        
        try {
          // Prepare request data
          const checkCodeData = {
            access_code: accessCode,
            ...(tgUsername ? { tg_username: tgUsername } : {}),
            ...(tgId ? { tg_id: parseInt(tgId, 10) } : {})
          };
          
          const result = await telegramCodeCheck({ tgCheckCode: checkCodeData }).unwrap();
          
          // Save token to localStorage
          if (result && result.access_token) {
            localStorage.setItem("token", result.access_token);
            
            // Dispatch storage event to notify other components about auth change
            window.dispatchEvent(new Event('storage'));
            
            // Clear any existing flag first
            localStorage.removeItem("showAuthSuccess");
            // Store success status in localStorage to show popup on home page
            localStorage.setItem("showAuthSuccess", "true");
            
            // Pre-fetch user data before navigation to ensure profile page loads with data
            try {
              // Wait a small amount of time for the token to be properly set
              await new Promise(resolve => setTimeout(resolve, 300));
              
              // Trigger data fetching to prime the cache
              await Promise.all([
                refetchUserInfo(),
                refetchUserEmail()
              ]);
              
              // Navigate to profile page after data is fetched
              navigate('/account/myprofile');
            } catch (fetchError) {
              console.error("Error pre-fetching user data:", fetchError);
              // Navigate anyway even if pre-fetch fails
              navigate('/account/myprofile');
            }
          }
        } catch (error: any) {
          const errorMsg = `Error validating access code. Please try again.`;
          setErrorMessage(errorMsg);
          setShowError(true);
        } finally {
          setIsProcessing(false);
        }
      }
    };
    
    processUrlParams();
  }, [searchParams, telegramCodeCheck, navigate, refetchUserInfo, refetchUserEmail]);
  
  return (
    <div className={styles.telegramLoginPage}>
      <div className={styles.telegramLoginContainer}>
        {isProcessing ? (
          <div className={styles.processingState}>
            <Spinner />
            <div style={{ marginTop: '20px' }}>Verifying your access code...</div>
          </div>
        ) : (
          <TelegramLoginForm onClose={handleCloseModal} />
        )}
      </div>
      
      <ErrorPopup
        show={showError}
        onHide={handleErrorHide}
        message={errorMessage}
      />
    </div>
  );
};

export default TelegramLoginPage; 