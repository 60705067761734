import { FC } from "react";
import { Button, SmallButton } from "../../shared/button/Button";
import { Text } from "../../shared/Text/Text";
import { Title } from "../../shared/title/Title";
import styles from "./ui/index.module.css";
import { ModalProps } from "../auth/login/model/types";

interface Props extends ModalProps {
  onYes: () => void;
  onNo: () => void;
}

const FillPreviousPopUp: FC<Props> = ({ onYes, onNo, isOpen }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.modal_overlay}>
      <div className={styles.modal_content}>
        <div style={{ marginTop: "18px" }}>
          <Text mt="18px" style={{ textAlign: "center" }}>
            Do you want to fill in the details of your previous posted listing?
          </Text>
        </div>
        <div className={styles.buttons}>
          <SmallButton onClick={onYes} $bg $icon>
            Yes
          </SmallButton>
          <SmallButton onClick={onNo} $border>
            No
          </SmallButton>
        </div>
      </div>
    </div>
  );
};

export default FillPreviousPopUp; 