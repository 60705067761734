import clsx from "./ui/index.module.css";
import Spinner from "../../shared/ui/Spinner";
import { SecondTitle } from "../../shared/secondTitle/SecondTitle";
import { Text } from "../../shared/Text/Text";
import Uploader from "../../entities/uploader/Uploader";
import { Input } from "../../shared/input/Input";
import Rooms from "../../shared/rooms/Rooms";
import DatePicker from "react-datepicker";
import { useEffect, useState, useCallback, ChangeEvent} from "react";
import { TextArea } from "../../shared/textArea/TextArea";
import Amenities from "../../shared/amenities/Amenities";
import { Button } from "../../shared/button/Button";
import {
  useAddFlatMutation,
  useDeleteAdvertMutation,
  useUploadImagesMutation,
  useUploadMultipleImagesMutation,
  useGetOneFlatQuery,
  useDeleteAllListingPicturesMutation,
  useEditAdvertMutation,
  useUploadMultipleImagesByUrlsMutation,
} from "../../app/redux/product/apiProducts";
import { useNavigate, useParams } from "react-router-dom";
import AddAdvertPopUp from "../../entities/addAdvertPopUp/addAdvertPopUp";
import { useModal } from "../../app/helpers/hooks/useModal";
import {
  useGetUserEmailQuery,
  useGetUserQuery,
} from "../../app/redux/auth/authApi";
import { STATUS_LISTING } from "../../app/constants/enum";
import FillPreviousPopUp from "../../entities/fillPreviousPopUp/FillPreviousPopUp";
import { useGetUsersProductsQuery } from "../../app/redux/product/apiProducts";

const AddFlat = () => {
  const { listing_id } = useParams();
  const isEditMode = !!listing_id;
  
  const [formData, setFormData] = useState({
    departure: null as Date | null,
    returnDate: null as Date | null,
    rooms: null as number | null,
    description: "",
    address: "",
    price: "",
    comments: "",
    country: "",
    city: "",
    uploadError: "",
    isUploading: false,
  });
  const [files, setFiles] = useState<FileList | null>(null);
  const [showFillPrevious, setShowFillPrevious] = useState(false);
  const [isLoadingPhotos, setIsLoadingPhotos] = useState(false);

  const navigate = useNavigate();

  const { isOpen, openModal, closeModal, ModalComponent } = useModal();

  const [deleteAdvert] = useDeleteAdvertMutation();
  const [addFlat] = useAddFlatMutation();
  const [uploadMultipleImages] = useUploadMultipleImagesMutation();
  const [uploadMultipleImagesByUrls] = useUploadMultipleImagesByUrlsMutation();
  const { data } = useGetUserQuery({});

  const { data: userResponse } = useGetUserEmailQuery({});

  const { data: previousListing, isSuccess } = useGetUsersProductsQuery({
    posted_only: true,
    limit: 1
  });

  const { data: existingFlat } = useGetOneFlatQuery(
    { id: listing_id },
    { skip: !isEditMode }
  );

  const [deleteAllListingPictures] = useDeleteAllListingPicturesMutation();
  const [editAdvert] = useEditAdvertMutation();

  useEffect(() => {
    console.log('Previous listing data:', previousListing);
  }, [previousListing]);

  useEffect(() => {
    if (isSuccess && previousListing && previousListing.length > 0 && !isEditMode) {
      setShowFillPrevious(true);
    }
  }, [isSuccess, previousListing, isEditMode]);

  useEffect(() => {
    if (isEditMode && existingFlat) {
      setFormData({
        ...formData,
        description: existingFlat.description || "",
        address: existingFlat.district || "",
        price: existingFlat.price || "",
        comments: existingFlat.comments || "",
        country: existingFlat.country || "",
        city: existingFlat.city || "",
        rooms: existingFlat.room ? parseInt(existingFlat.room) : null,
        departure: existingFlat.date_from ? new Date(existingFlat.date_from) : null,
        returnDate: existingFlat.date_to ? new Date(existingFlat.date_to) : null,
      });

      if (existingFlat.used_listing_pictures?.length) {
        const dataTransfer = new DataTransfer();
        
        existingFlat.used_listing_pictures.forEach((pic: any) => {
          const isVideo = pic.picture_url.toLowerCase().endsWith('.mov');
          const file = new File(
            [''],
            pic.picture_url.split('/').pop() || 'image.jpg',
            { type: isVideo ? 'video/quicktime' : 'image/jpeg' }
          );
          
          Object.defineProperties(file, {
            'preview_url': {
              value: pic.picture_url,
              writable: false
            },
            'url': {
              value: pic.picture_url,
              writable: false
            }
          });
          
          dataTransfer.items.add(file);
        });
        
        setFiles(dataTransfer.files);
      }
    }
  }, [isEditMode, existingFlat]);

  const socialNetworks = {
    Instagram: data?.instagram,
    LinkedIn: data?.linkedin,
    WhatsApp: data?.whatsapp,
    Telegram: data?.telegram,
  };

  const handleInputChange = (field: string, value: any) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const archiveItem = async (item: any) => {
    await deleteAdvert({ listing_id: item?.listing_id });
    navigate("/account/myarchivedads");
    window.location.reload();
  };

  const fetchImageContent = async (url: string): Promise<Blob> => {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch image: ${response.status}`);
    }
    return await response.blob();
  };

  const handleSave = async (archive = false) => {
    if (!files) {
      setFormData(prev => ({ ...prev, uploadError: "Please select files to upload" }));
      return;
    }

    setFormData(prev => ({ ...prev, isUploading: true, uploadError: "" }));

    const data = {
      description: formData.description || "",
      date_from: formData.departure ? formData.departure.toISOString() : "",
      date_to: formData.returnDate ? formData.returnDate.toISOString() : "",
      country: formData.country || "",
      city: formData.city || "",
      price: formData.price || "",
      comments: formData.comments || "",
      district: formData.address || "",
      room: formData.rooms ? formData.rooms.toString() : "",
      status: STATUS_LISTING.FINISHED,
      listing_id: isEditMode ? listing_id : undefined,
    };

    try {
      let result;
      
      if (isEditMode) {
        await deleteAllListingPictures(listing_id);
        result = await editAdvert({ 
          listing_id: listing_id,
          data: data 
        }).unwrap();
      } else {
        result = await addFlat(data).unwrap();
      }

      if (result?.listing_id) {
        const localFiles = Array.from(files).filter(file => !('preview_url' in file));
        const previewUrls = Array.from(files)
          .filter(file => 'preview_url' in file)
          .map(file => (file as any).preview_url);

        if (localFiles.length > 0) {
          const formData = new FormData();
          localFiles.forEach(file => {
            formData.append('files', file);
          });

          await uploadMultipleImages({ 
            data: formData, 
            listing_id: result.listing_id 
          }).unwrap();
        }

        if (previewUrls.length > 0) {
          await uploadMultipleImagesByUrls({
            urls: previewUrls,
            listing_id: result.listing_id
          }).unwrap();
        }
        
        if (archive && result) {
          await archiveItem(result);
        } else {
          navigate("/account/myprofile?success=true");
        }
      }
    } catch (error) {
      console.error("Failed to save the advertisement:", error);
      setFormData(prev => ({ 
        ...prev, 
        uploadError: "Failed to save the advertisement. Please try again." 
      }));
    } finally {
      setFormData(prev => ({ ...prev, isUploading: false }));
    }
  };

  const handleSaveClick = async () => {
    const hasSocialNetworks = Object.values(socialNetworks).some(
      (value) => !!value
    );

    if (hasSocialNetworks) {
      await handleSave();
    } else {
      await handleSave();
      openModal();
    }
  };

  const handleFillPrevious = () => {
    if (previousListing && previousListing[0]) {
      setIsLoadingPhotos(true);
      const prev = previousListing[0];
      
      try {
        const dataTransfer = new DataTransfer();
        
        prev.used_listing_pictures.forEach((pic: any) => {
          const isVideo = pic.picture_url.toLowerCase().endsWith('.mov'); 
          
          const file = new File(
            [''],
            pic.picture_url.split('/').pop() || 'image.jpg',
            { 
              type: isVideo ? 'video/quicktime' : 'image/jpeg'
            }
          );

          Object.defineProperties(file, {
            'preview': {
              value: pic.picture_url,
              writable: false
            },
            'preview_url': {
              value: pic.picture_url,
              writable: false
            },
            'url': {
              value: pic.picture_url,
              writable: false
            }
          });
          
          dataTransfer.items.add(file);
        });
        
        setFiles(dataTransfer.files);
        
        setFormData({
          ...formData,
          description: prev.description || "",
          address: prev.district || "",
          price: prev.price || "",
          comments: prev.comments || "",
          country: prev.country || "",
          city: prev.city || "",
          rooms: prev.room || null,
          departure: null,
          returnDate: null,
        });
      } catch (error) {
        console.error('Error setting up files:', error);
      } finally {
        setIsLoadingPhotos(false);
      }
    }
    setShowFillPrevious(false);
  };

  const getMimeType = (extension: string): string => {
    const mimeTypes: { [key: string]: string } = {
      'jpg': 'image/jpeg',
      'jpeg': 'image/jpeg',
      'png': 'image/png',
      'gif': 'image/gif',
      'webp': 'image/webp',
      'mp4': 'video/mp4',
      'mov': 'video/quicktime',
      'avi': 'video/x-msvideo',
      'webm': 'video/webm'
    };
    
    return mimeTypes[extension] || 'image/jpeg';
  };

  return (
    <div className={`${clsx.wrapper} container`}>
      <SecondTitle
        className={clsx.title}
        onClick={() => navigate("/add_apartment")}
      >
        {isEditMode ? 'Edit apartment' : 'Add apartment'}
      </SecondTitle>
      <div className={clsx.upload}>
        <SecondTitle>Photos of the apartment</SecondTitle>
        <div className={clsx.upload_item}>
          <Text>Upload no more than 8 photos of your apartment.</Text>
          {isLoadingPhotos ? (
            <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
              <Spinner />
            </div>
          ) : (
            <Uploader
              required
              imageFiles={files}
              setImageFiles={setFiles}
            />
          )}
        </div>
      </div>
      <div className={clsx.location}>
        <SecondTitle>Location</SecondTitle>
        <div>
          <Input
            required
            placeholder="Enter the country"
            value={formData.country}
            onChange={(e) => handleInputChange("country", e.target.value)}
          />
          <Input
            required
            placeholder="Enter the city"
            value={formData.city}
            onChange={(e) => handleInputChange("city", e.target.value)}
          />
          <Input
            required
            placeholder="Enter the district"
            value={formData.address}
            onChange={(e) => handleInputChange("address", e.target.value)}
          />
        </div>
      </div>
      <div className={clsx.rooms}>
        <SecondTitle>Number of rooms</SecondTitle>
        <div>
          <Rooms handlegetRooms={(data) => handleInputChange("rooms", data)} />
        </div>
      </div>
      <div className={clsx.time}>
        <SecondTitle>Departure date</SecondTitle>
        <div>
          <DatePicker
            required
            selected={formData.departure}
            onChange={(date) => handleInputChange("departure", date)}
            placeholderText="Select a date"
            className={clsx.date_picker}
          />
        </div>
      </div>
      <div className={clsx.time}>
        <SecondTitle>Return to the apartment</SecondTitle>
        <div>
          <DatePicker
            required
            selected={formData.returnDate}
            onChange={(date) => handleInputChange("returnDate", date)}
            placeholderText="Select a date"
            className={clsx.date_picker}
          />
        </div>
      </div>
      <div className={clsx.description}>
        <SecondTitle>Description of the apartment</SecondTitle>
        <div>
          <TextArea
            required
            placeholder="Enter a description of the apartment"
            value={formData.description}
            onChange={(e) => handleInputChange("description", e.target.value)}
          />
        </div>
      </div>
      <div>
        {/*<SecondTitle>Amenities</SecondTitle>
        <div>
          <Amenities />
        </div>*/}
      </div>
      <div className={clsx.cost}>
        <SecondTitle>Cost</SecondTitle>
        <Text>
          For comfortable use of the community for all participants, the rent
          should cost as the cost of a long-term lease.
        </Text>
        <div>
          <Input
            required
            placeholder="$/night"
            value={formData.price}
            onChange={(e) => handleInputChange("price", e.target.value)}
          />
        </div>
      </div>
      <div className={clsx.comment}>
        <SecondTitle>Comment</SecondTitle>
        <Text>
          If you have any suggestions or comments about the apartment, please add them.
        </Text>
        <div>
          <TextArea
            placeholder="Enter the comment"
            value={formData.comments}
            onChange={(e) => handleInputChange("comments", e.target.value)}
          />
        </div>
      </div>
      {formData.uploadError && (
        <div className={clsx.error}>
          {formData.uploadError}
        </div>
      )}
      <Button
        onClick={handleSaveClick}
        style={{ alignSelf: "flex-start" }}
        $bg
        $icon
        disabled={formData.isUploading}
      >
        {formData.isUploading ? "Saving..." : "Save"}
      </Button>
      {formData.isUploading && (
          <div className={clsx.overlay}>
            <Spinner />
          </div>
        )}

      {showFillPrevious && (
        <FillPreviousPopUp
          onYes={handleFillPrevious}
          onNo={() => setShowFillPrevious(false)}
          closeModal={() => setShowFillPrevious(false)}
          openModal={() => setShowFillPrevious(true)}
          isOpen={showFillPrevious}
        />
      )}

      <ModalComponent>
        <AddAdvertPopUp
          closeModal={closeModal}
          openModal={openModal}
          isOpen={isOpen}
          handleSave={handleSave}
        />
      </ModalComponent>
    </div>
  );
};

export default AddFlat;
