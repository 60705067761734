import { useState, useEffect } from "react";

const useAuth = () => {
  const [token, setToken] = useState<string | false>(false);

  useEffect(() => {
    // Check localStorage for token on mount
    const checkToken = () => {
      const storedToken = localStorage.getItem("token");
      if (storedToken) {
        setToken(storedToken);
      } else {
        setToken(false);
      }
    };

    // Initial check
    checkToken();

    // Add event listener for storage changes
    window.addEventListener('storage', checkToken);

    // Clean up
    return () => {
      window.removeEventListener('storage', checkToken);
    };
  }, []);

  return token;
};

export default useAuth;

export const logout = () => {
  localStorage.removeItem("token");
  // Dispatch storage event to notify other components
  window.dispatchEvent(new Event('storage'));
};
