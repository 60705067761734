import React from 'react';
import { TestimonialAvatar, TestimonialQuote, TestimonialAuthor } from '../components/Content';
import kirillImage from '../../../assets/images/kirill.png';

interface Step2Props {
  onNext: () => void;
  onPrev: () => void;
}

const testimonialText = "I've experienced FlatSharing in Berlin, and several times in Amsterdam and London. I don't think I would have gone if this option didn't exist. It's really awesome! It's especially fun to discover the number of unexpected mutual friends with hosts on social media.";

const Step2: React.FC<Step2Props> = ({ onNext, onPrev }) => {
  return (
    <div className="onboarding-step">
      <div className="step-content">
        <TestimonialAvatar image={kirillImage} />
        <TestimonialQuote text={testimonialText} />
        <TestimonialAuthor 
          name="Kirill"
          occupation="Founder of ozma.io, AI & Data Consultant"
          website="ozma.io"
        />
      </div>
    </div>
  );
};

export default Step2; 