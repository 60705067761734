import React, { useState } from 'react';
import styles from '../components/TrialOption/TrialOption.module.css';
import { Button } from '../components/Registration';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/redux/store';
import { createPaymentData } from '../../../app/helpers/payment';
import { usePaymentMutation, useGetUserEmailQuery, useUserAccessRequestMutation } from '../../../app/redux/auth/authApi';
import Spinner from '../../../shared/ui/Spinner';
import { 
  TrialOptionCard,
  PlaneIcon,
  SmallDescription,
  PriceOption,
  PriceTitle,
  HelpText
} from '../components/TrialOption';

interface Step17Props {
  onNext: () => void;
  onPrev: () => void;
}

const Step17: React.FC<Step17Props> = ({ onNext, onPrev }) => {
  const [selectedPrice, setSelectedPrice] = useState('$0');
  const [paymentRequest, { isLoading: isPaymentLoading }] = usePaymentMutation();
  const [userAccessRequest, { isLoading: isUserAccessLoading }] = useUserAccessRequestMutation();
  const { data: userEmail, isLoading: isUserEmailLoading } = useGetUserEmailQuery({});
  const onboardingData = useSelector((state: RootState) => state.onboarding.data);
  const [isProcessing, setIsProcessing] = useState(false);

  // Loading state combining all loading states
  const isLoading = isPaymentLoading || isUserEmailLoading || isProcessing || isUserAccessLoading;

  const handlePriceSelect = (price: string) => {
    setSelectedPrice(price);
  };

  const handlePayment = async () => {
    if (isUserEmailLoading || !userEmail) {
      console.error('User data not available');
      onNext();
      return;
    }

    setIsProcessing(true);

    try {
      // First, create user access request with status 'created'
      await userAccessRequest({ data: { status: 'sent_to_admins' } });
      
      // Then handle payment based on selected price
      const data = selectedPrice === '$0'
        ? createPaymentData(userEmail, 7) // Free trial with 7 days
        : createPaymentData(userEmail);    // Paid with no trial
      
      const { data: paymentResponse } = await paymentRequest({ data });
      
      if (paymentResponse?.stripe_link) {
        window.location.href = paymentResponse.stripe_link;
      } else {
        // If no payment link is returned, just move to next step
        onNext();
      }
    } catch (error) {
      console.error('Payment error:', error);
      // In case of error, still continue to next step
      onNext();
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="onboarding-step">
      <div className="step-content">
        <div className={styles.trialOptionContainer}>
          <TrialOptionCard>
            <PlaneIcon text="Trial upgrade" />
          </TrialOptionCard>
          
          <SmallDescription 
            text="We get it. Not everyone (students etc) wants to commit to a plan without trying it first. So we'd like to offer you a 7-day trial for free."
          />
          
          <SmallDescription 
            text="Or, if you'd like to help us build a better future for travelers choose the $28 option for a 7-day trial."
          />
          
          <div className={styles.greyBox}>
            <PriceTitle />
            
            <div className={styles.priceOptions}>
              <PriceOption 
                price="$0" 
                selected={selectedPrice === '$0'} 
                onClick={() => handlePriceSelect('$0')}
              />
              
              <PriceOption 
                price="$28" 
                selected={selectedPrice === '$28'} 
                onClick={() => handlePriceSelect('$28')}
              />
            </div>
            
            <HelpText />
          </div>
          
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Button 
              label={isLoading ? "Processing..." : "See my plan"}
              onClick={handlePayment}
              disabled={isLoading}
            />
          </div>
          
          {/* Loading Spinner */}
          {isLoading && <Spinner overlay={true} />}
        </div>
      </div>
    </div>
  );
};

export default Step17; 