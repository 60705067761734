import React from 'react';
import styles from './Location.module.css';

interface LocationCardProps {
  id: string;
  icon: string;
  name: string;
  selected: boolean;
  onClick: (id: string) => void;
}

const LocationCard: React.FC<LocationCardProps> = ({ 
  id, 
  icon, 
  name, 
  selected, 
  onClick 
}) => {
  return (
    <div className={styles.locationCardContainer}>
      <div 
        className={`${styles.locationCard} ${selected ? styles.selected : ''}`}
        onClick={() => onClick(id)}
      >
        <img 
          src={icon} 
          alt={name} 
          className={styles.locationIcon} 
        />
      </div>
      <div className={styles.locationName}>
        {name}
      </div>
    </div>
  );
};

export default LocationCard; 