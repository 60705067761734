import React from 'react';
import styles from './TrialOption.module.css';

interface SmallDescriptionProps {
  text: string;
}

const SmallDescription: React.FC<SmallDescriptionProps> = ({ text }) => {
  return (
    <div className={styles.smallDescription}>
      {text}
    </div>
  );
};

export default SmallDescription; 