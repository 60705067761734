import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './styles.module.css';
import { useVerifyMutation } from '../../app/redux/auth/authApi';
import Spinner from '../../shared/ui/Spinner';
import { ErrorPopup } from '../../shared/ui/Popup/ErrorPopup';
import { Button } from '../../shared/button/Button';

const EmailVerificationPage: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  
  const [verifyEmail, { isLoading }] = useVerifyMutation();
  const [verificationStatus, setVerificationStatus] = useState<'pending' | 'success' | 'error'>('pending');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showError, setShowError] = useState<boolean>(false);
  
  useEffect(() => {
    const verifyToken = async () => {
      try {
        // Get token from URL query parameters
        const searchParams = new URLSearchParams(location.search);
        const token = searchParams.get('token');
        
        if (!token) {
          setVerificationStatus('error');
          setErrorMessage('No verification token provided');
          setShowError(true);
          return;
        }
        
        // Call the verify API
        const response = await verifyEmail({ 
          data: { token }
        });
        
        if ('data' in response) {
          setVerificationStatus('success');
        } else if ('error' in response) {
          setVerificationStatus('error');
          const errorData = response.error as any;
          const errorMsg = JSON.stringify(errorData, null, 2);
          setErrorMessage(errorMsg);
          setShowError(true);
        }
      } catch (error) {
        setVerificationStatus('error');
        setErrorMessage(error instanceof Error ? error.message : 'Unknown error occurred');
        setShowError(true);
      }
    };
    
    verifyToken();
  }, [location.search, verifyEmail]);
  
  const handleCloseError = () => {
    setShowError(false);
  };
  
  const goToHome = () => {
    navigate('/');
  };
  
  if (isLoading) {
    return <Spinner overlay={true} />;
  }
  
  return (
    <div className={styles.verificationPage}>
      <div className={styles.verificationContainer}>
        {verificationStatus === 'success' ? (
          <>
            <div className={`${styles.verificationIcon} ${styles.success}`}>✓</div>
            <h2 className={styles.verificationMessage}>
              Your email has been successfully verified!
            </h2>
            <p>Thank you for verifying your email address. You can now access all features of our platform.</p>
          </>
        ) : verificationStatus === 'error' ? (
          <>
            <div className={`${styles.verificationIcon} ${styles.error}`}>✗</div>
            <h2 className={styles.verificationMessage}>
              Email verification failed
            </h2>
            <p>We encountered an error while verifying your email. Please try again later or contact support.</p>
          </>
        ) : null}
        
        <div className={styles.homeButton}>
          <Button type="button" $bg $icon onClick={goToHome}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            </svg>
            Go to Homepage
          </Button>
        </div>
      </div>
      
      <ErrorPopup 
        show={showError} 
        onHide={handleCloseError} 
        message={errorMessage} 
      />
    </div>
  );
};

export default EmailVerificationPage; 