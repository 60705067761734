import React from 'react';
import { OnboardingFirstImage, OnboardingHeading, OnboardingDescription } from '../components/Content';
import peopleWalkingImage from '../../../assets/images/people_walking.png';

interface Step1Props {
  onNext: () => void;
}

const Step1: React.FC<Step1Props> = ({ onNext }) => {
  return (
    <div className="onboarding-step">
      <div className="step-content">
        <OnboardingFirstImage image={peopleWalkingImage} />
        <OnboardingHeading text="Hello, this is FlatSharing" />
        <OnboardingDescription text="We're very happy to see you here 🙂 You need to answer a few questions and we'll give you access to the FlatSharing community. You'll be able to rent apartments at 2-3 times cheaper than on Airbnb, essentially at cost from your peers. Plus, you can rent out your space too." />
      </div>
    </div>
  );
};

export default Step1; 