import React from 'react';
import { IconCard, CommunityHeading, CommunityDescription } from '../components/Community';
import usersIcon from '../../../assets/icons/users.svg';

interface Step9Props {
  onNext: () => void;
  onPrev: () => void;
}

const Step9: React.FC<Step9Props> = ({ onNext, onPrev }) => {
  return (
    <div className="onboarding-step">
      <div className="step-content">
        <IconCard>
          <img 
            src={usersIcon} 
            alt="Community" 
            style={{ width: '36px', height: '36px' }}
          />
        </IconCard>
        
        <CommunityHeading text="Welcome to Our Community" />
        
        <CommunityDescription text="Besides renting apartments, we're also a community where people have found friends, buddies, and even jobs! Our community includes executives of major companies, well-known creative individuals, and genuinely great people who are always ready to help." />
      </div>
    </div>
  );
};

export default Step9; 