import React, { FC } from "react";
import { Title } from "../../../shared/title/Title";
import clsx from "./ui/index.module.css";
import { Text } from "../../../shared/Text/Text";
import { Button } from "../../../shared/button/Button";
import { GreyCard, BackgroundImage } from "../../../shared/greyCard/GreyCard";
import GreyCardWithImage from "../../../shared/greyCard/GreyCard";
import cityView from "../../../assets/png/cityView.png";
import Alex from "../../../assets/png/Alex.png";
import { AddressGreyWrapper } from "../../../shared/addressGreyWrapperProps/AddressGreyWrapperProps";
import { AddressTitle } from "../../../shared/addressTitle/AddressTitle";
import { Lessor } from "../../../shared/lessor/Lessor";
import { LessorLeft } from "../../../shared/lessor/LessorLeft";
import { LessorAvatar } from "../../../shared/lessor/LessorAvatar";
import { LessorRight } from "../../../shared/lessor/LessorRight";
import { useNavigate } from "react-router-dom";
import { getRedirectLink } from "../../../shared/helpers/getRedirectLink";

const Hero: FC = () => {
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className={`container ${clsx.hero}`}>
      <GreyCardWithImage id={clsx.hero_card} $hero>
        <Title id={clsx.hero_card_title}>Travel 2-4 times cheaper</Title>
        <Text id={clsx.hero_card_text}>
          Also rent out your apartment to a community of like-minded individuals
          while you are away
        </Text>
        <div className={clsx.button_group}>
          <Button onClick={() => navigate(getRedirectLink("Hero"))} $icon $bg>
            Try in 5 minutes
          </Button>
          {/* <SecondaryText>takes only 5 minutes</SecondaryText> */}
        </div>
      </GreyCardWithImage>
      
      <GreyCard
        id={clsx.hero_img_card}
        style={{ alignItems: "flex-start", justifyContent: "space-between" }}
      >
        {windowWidth > 600 && (
          <BackgroundImage
            src={cityView}
            alt="City view"
            loading="eager"
          />
        )}
        <AddressGreyWrapper>
          <AddressTitle>Central London</AddressTitle>
          <Text
            $through
            style={{
              textDecoration: "line-through",
              textDecorationColor: "rgba(0,0,0,0.5)",
              textDecorationThickness: "2px",
            }}
          >
            300$+/night Airbnb
          </Text>
          <Text>60$/night</Text>
        </AddressGreyWrapper>
        <Lessor className={clsx.lesor}>
          <LessorLeft>
            <LessorAvatar src={Alex} />
            <div>
              <AddressTitle>Alex</AddressTitle>
              <Text>Developer in Booking, dog lover</Text>
            </div>
          </LessorLeft>
          <LessorRight className={clsx.lesor_right}>
            <Text
              className={clsx.text}
              style={{ color: "white", alignSelf: "center" }}
            >
              Live at Alex's flat
            </Text>
          </LessorRight>
        </Lessor>
      </GreyCard>
    </div>
  );
};

export default Hero;
