import React from 'react';
import styles from './Explore.module.css';

interface ExploreDescriptionProps {
  text?: string;
}

const defaultProps = {
  text: 'Discover apartments in countries like UK, France, Italy, USA, Germany, Netherlands, Russia, Thailand, Bali, Armenia, Georgia, Britain, France, the United States, Switzerland, Serbia, Turkey, Montenegro, Sweden, Cyprus, and more.',
};

const ExploreDescription: React.FC<ExploreDescriptionProps> = ({ 
  text = defaultProps.text 
}) => {
  return (
    <div className={styles.exploreDescription}>
      {text}
    </div>
  );
};

export default ExploreDescription; 