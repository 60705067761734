import React from 'react';
import styles from '../components/Trial/Trial.module.css';
import { Button } from '../components/Registration';
import { NumberCard, TrialTitle, TrialDescription } from '../components/Trial';

interface Step16Props {
  onNext: () => void;
  onPrev: () => void;
}

const Step16: React.FC<Step16Props> = ({ onNext, onPrev }) => {
  return (
    <div className="onboarding-step">
      <div className="step-content">
        <div className={styles.trialContainer}>
          <NumberCard number={7} />
          <TrialTitle text="We've upgraded your FlatSharing trial" />
          <TrialDescription text="Now you can enjoy all the perks of FlatSharing for 7 days" />
          <Button 
            label="Take me to my plan" 
            onClick={onNext}
          />
        </div>
      </div>
    </div>
  );
};

export default Step16; 