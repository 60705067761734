import React from 'react';
import styles from './Content.module.css';

interface TestimonialAuthorProps {
  name?: string;
  occupation?: string;
  website?: string;
}

const defaultProps = {
  name: 'Kirill',
  occupation: 'Founder of ozma.io, AI & Data Consultant',
  website: 'ozma.io'
};

const TestimonialAuthor: React.FC<TestimonialAuthorProps> = ({ 
  name = defaultProps.name,
  occupation = defaultProps.occupation,
  website = defaultProps.website
}) => {
  return (
    <div className={styles.testimonialAuthorContainer}>
      <div className={styles.testimonialAuthorName}>
        {name}
      </div>
      <div className={styles.testimonialAuthorOccupation}>
        Founder of <a href={`https://${website}`} className={styles.testimonialLink}>{website}</a>, AI & Data Consultant
      </div>
    </div>
  );
};

export default TestimonialAuthor; 