import { useState, useEffect, useRef } from 'react';
import { useFbTrackingMutation } from '../app/redux/product/apiProducts';
import { saveOnboardingData } from '../utils/localStorage';

interface TrackingResult {
  isLoading: boolean;
  randomString: string;
}

interface TrackingOptions {
  saveToOnboarding?: boolean;
  redirectToTelegram?: boolean;
}

/**
 * Custom hook for handling tracking logic with Facebook pixel and UTM parameters
 * @param options Configuration options for tracking
 * @returns Tracking state and generated random string
 */
export const useTracking = (options: TrackingOptions = {}): TrackingResult => {
  const { saveToOnboarding = false, redirectToTelegram = false } = options;
  const [isLoading, setIsLoading] = useState(true);
  const [randomString, setRandomString] = useState<string>('');
  const [fbTracking] = useFbTrackingMutation();
  
  // Use refs to track state without causing re-renders
  const hasTracked = useRef(false);
  const shouldTrack = useRef(false); // Initialize as false
  
  // Update shouldTrack ref when props change
  useEffect(() => {
    const newShouldTrack = saveToOnboarding || redirectToTelegram;
    
    // If tracking should now happen but didn't before, reset hasTracked
    if (newShouldTrack && !shouldTrack.current) {
      console.log('Tracking conditions changed from false to true, resetting hasTracked');
      hasTracked.current = false;
    }
    
    shouldTrack.current = newShouldTrack;
    
    console.log('Tracking configuration updated:', { 
      saveToOnboarding, 
      redirectToTelegram, 
      shouldTrack: shouldTrack.current,
      hasTracked: hasTracked.current
    });
  }, [saveToOnboarding, redirectToTelegram]);
  
  useEffect(() => {
    // Skip if we shouldn't track or have already tracked
    if (!shouldTrack.current || hasTracked.current) {
      console.log('Skipping tracking:', { 
        shouldTrack: shouldTrack.current, 
        hasTracked: hasTracked.current 
      });
      setIsLoading(false);
      return;
    }
    
    // Mark as tracked immediately to prevent duplicate executions
    hasTracked.current = true;
    console.log('Starting tracking process...');
    
    const generateRandomString = (length: number): string => {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    };

    const getFacebookCookies = () => {
      const cookies = document.cookie.split(';');
      let fbp = '';
      let fbc = '';

      cookies.forEach(cookie => {
        const trimmedCookie = cookie.trim();
        if (trimmedCookie.startsWith('_fbp=')) {
          fbp = trimmedCookie.substring(5);
        } else if (trimmedCookie.startsWith('_fbc=')) {
          fbc = trimmedCookie.substring(5);
        }
      });

      return { fbp, fbc };
    };

    const getUtmParams = () => {
      const queryString = window.location.search;
      const utmFullString = queryString.startsWith('?') ? queryString.substring(1) : queryString;
      
      // Initialize UTM parameters with proper structure
      const utmParams = {
        utmFullString,
        utm_source: '',
        utm_medium: '',
        utm_campaign: ''
      };
      
      if (utmFullString) {
        // Handle both & and / as separators
        const normalizedParams = utmFullString.replace(/\//g, '&');
        const params = new URLSearchParams(normalizedParams);
        
        // Extract specific UTM parameters if they exist
        if (params.has('utm_source')) utmParams.utm_source = params.get('utm_source') || '';
        if (params.has('utm_medium')) utmParams.utm_medium = params.get('utm_medium') || '';
        if (params.has('utm_campaign')) utmParams.utm_campaign = params.get('utm_campaign') || '';
      }
      
      return utmParams;
    };

    const handleTracking = async () => {
      try {
        // Generate random string first
        const randomStr = generateRandomString(16);
        
        // Update state with the generated string
        setRandomString(randomStr);
        
        // Track ViewContent event
        if (typeof window.fbq === "function") {
          window.fbq('track', 'ViewContent');
        }
        
        // Get Facebook cookies
        const { fbp, fbc } = getFacebookCookies();
        
        // Get UTM parameters
        const utmParams = getUtmParams();
        
        // Log the tracking attempt
        console.log('Sending tracking data to backend', { randomStr, saveToOnboarding, redirectToTelegram });
        
        // Send tracking data to backend
        await fbTracking({
          utm: randomStr,
          fbp,
          fbc,
          utm_full_string: utmParams.utmFullString || '',
          utm_source: utmParams.utm_source || '',
          utm_medium: utmParams.utm_medium || '',
          utm_campaign: utmParams.utm_campaign || ''
        });

        // Save to onboarding data if needed
        if (saveToOnboarding) {
          saveOnboardingData('trackingId', randomStr);
          console.log('Saved tracking ID to onboarding data:', randomStr);
        }
        
        // Redirect to Telegram if needed
        if (redirectToTelegram) {
          window.location.href = `https://t.me/flatsharing_close_bot?start=${randomStr}`;
        }
      } catch (error) {
        console.error("Error during tracking:", error);
        
        // If tracking fails but redirect is needed, still redirect
        if (redirectToTelegram) {
          const fallbackString = randomString || generateRandomString(16);
          window.location.href = `https://t.me/flatsharing_close_bot?start=${fallbackString}`;
        }
      } finally {
        setIsLoading(false);
      }
    };

    // Execute tracking
    handleTracking();
    
    // This effect should only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    randomString
  };
};

export default useTracking; 