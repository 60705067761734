import { SecondTitle } from "../../shared/secondTitle/SecondTitle";
import Questions from "../../entities/questions/Questions";
import TravelWithFlatSharing from "../../entities/travelWithFlatSharing/TravelWithFlatSharing";
import ReactMarkdown from "react-markdown";

import styles from "./ui/index.module.css";
import data from "./content.json";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";

interface contentType {
  id: number;
  image?: string;
  text?: string;
  markdownFile?: string;
}

interface blogTypes {
  id: number;
  title: string;
  secondary_title?: string;
  content: contentType[];
}

export const DetailsBlog = () => {
  const { id } = useParams();

  const blog = data.find((item: blogTypes) => item.id.toString() == id);

  const [markdownContent, setMarkdownContent] = useState<{
    [key: number]: string;
  }>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loadError, setLoadError] = useState<string | null>(null);

  useEffect(() => {
    const fetchMarkdownContent = async () => {
      setIsLoading(true);
      setLoadError(null);
      const content: { [key: number]: string } = {};
      
      if (!blog?.content || blog.content.length === 0) {
        setLoadError("Blog content not found");
        setIsLoading(false);
        return;
      }
      
      try {
        for (const item of blog.content) {
          if ("markdownFile" in item && item.markdownFile) {
            try {
              console.log(`Fetching markdown from: ${item.markdownFile}`);
              const response = await fetch(item.markdownFile);
              
              if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
              }
              
              const text = await response.text();
              console.log(`Successfully loaded markdown, length: ${text.length}`);
              content[item.id] = text;
            } catch (error) {
              console.error(
                `Error fetching Markdown content for item ${item.id}:`,
                error
              );
              content[item.id] = `Error loading content: ${
                (error as Error).message || "Unknown error"
              }`;
              setLoadError(`Error loading content: ${(error as Error).message || "Unknown error"}`);
            }
          }
        }
        setMarkdownContent(content);
      } catch (error) {
        console.error("Error in fetchMarkdownContent:", error);
        setLoadError(`General error: ${(error as Error).message || "Unknown error"}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMarkdownContent();
  }, [blog]);

  if (isLoading) {
    return <div className="container">Loading blog content...</div>;
  }

  if (!blog) {
    return <div className="container">Blog not found</div>;
  }

  return (
    <div className={`${styles.details_wrapper} container`}>
      <h1>{blog.title}</h1>
      {blog.secondary_title && (
        <SecondTitle>{blog.secondary_title}</SecondTitle>
      )}
      {loadError && (
        <div className={styles.error_message}>
          {loadError}
        </div>
      )}
      {blog.content.map((item: contentType) => (
        <div key={item.id} className={styles.block}>
          {item.image && (
            <img src={item.image} alt={item.text || "Blog image"} />
          )}
          <div className={styles.text_block}>
            {item.markdownFile && markdownContent[item.id] ? (
              <ReactMarkdown>{markdownContent[item.id]}</ReactMarkdown>
            ) : item.text ? (
              <p>{item.text}</p>
            ) : null}
          </div>
        </div>
      ))}
      <Questions />
      <TravelWithFlatSharing />
    </div>
  );
};
