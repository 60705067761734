import styles from "./ui/index.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../../entities/profile/SideBar/Sidebar";
import MyAds from "../../entities/profile/MyAds/MyAds";
import MyProfile from "../../entities/profile/MyProfile/MyProfile";
import Subscription from "../../entities/profile/Subscription/Subscription";
import { useGetUserEmailQuery } from "../../app/redux/auth/authApi";

const PersonalAccount = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className={`container ${styles.account_wrapper}`}>
      <Sidebar />
      {(location.pathname === "/account/myads" ||
        location.pathname === "/account/myarchivedads" ) && <MyAds />}

      {location.pathname == "/account/myprofile" && <MyProfile />}
      {location.pathname == "/account/subscription" && <Subscription />}
    </div>
  );
};

export default PersonalAccount;
