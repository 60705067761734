import React from 'react';
import styles from './Gift.module.css';
import giftImage from '../../../../assets/images/gift.png';

const GiftImage: React.FC = () => {
  return (
    <div 
      className={styles.giftImage} 
      style={{ backgroundImage: `url(${giftImage})` }}
    />
  );
};

export default GiftImage; 