import React from 'react';

interface HelperTextProps {
  text: string;
}

const defaultProps = {
  text: 'Please specify up to 3 tags',
};

const HelperText: React.FC<HelperTextProps> = ({ 
  text = defaultProps.text 
}) => {
  const styles = {
    text: {
      color: '#545454',
      fontSize: '12px',
      fontFamily: 'Lexend Deca',
      lineHeight: '16px',
      textAlign: 'center' as const,
      marginBottom: '32px',
    },
  };

  return (
    <div style={styles.text}>
      {text}
    </div>
  );
};

export default HelperText; 