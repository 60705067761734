import { FC, memo } from "react";
import LoginForm from "./LoginForm";
import { ModalProps } from "./model/types";

// Use memo to prevent unnecessary re-renders
const Login: FC<ModalProps> = memo((props) => {
  return <LoginForm {...props} />;
});

export default Login;
