import React from 'react';
import styles from '../components/Gift/Gift.module.css';
import { Button } from '../components/Registration';
import { GiftImage, GiftTitle, GiftDescription } from '../components/Gift';

interface Step15Props {
  onNext: () => void;
  onPrev: () => void;
}

const Step15: React.FC<Step15Props> = ({ onNext, onPrev }) => {
  return (
    <div className="onboarding-step">
      <div className="step-content">
        <div className={styles.giftContainer}>
          <GiftImage />
          <GiftTitle text="Here's your welcome gift" />
          <GiftDescription text="We're so excited that you're joining us!" />
          <Button 
            label="Open my gift" 
            onClick={onNext}
          />
        </div>
      </div>
    </div>
  );
};

export default Step15; 