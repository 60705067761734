import styles from "../ui/index.module.css";
import { SecondTitle } from "../../../shared/secondTitle/SecondTitle";
import {
  useGetUserEmailQuery,
  useGetUserQuery,
  useLogoutMutation,
} from "../../../app/redux/auth/authApi";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../../shared/button/Button";
import { useModal } from "../../../app/helpers/hooks/useModal";
import Login from "../../auth/login/Login";
import { useEffect, useState } from "react";
import { useRestrictedNavigation } from "../../../app/helpers/hooks/useRestrictedNavigation";

const Sidebar = () => {
  const { data: userInfoResponse } = useGetUserQuery({});
  const [logout] = useLogoutMutation();
  const navigate = useNavigate();
  const location = useLocation();
  const { isOpen, openModal, closeModal, ModalComponent } = useModal();
  const [tokenState, setTokenState] = useState<string | null>(null);

  const handleLogout = async () => {
    try {
      await logout("");
      localStorage.removeItem("token");
      navigate("/");
      window.location.reload();
    } catch (error) {}
  };

  const handleGetToken = () => {
    const token = localStorage.getItem("token");

    setTokenState(token);
    return tokenState;
  };

  useEffect(() => {
    handleGetToken();
  }, []);

  const { handleNavigationToRestrictedContent } = useRestrictedNavigation();

  return (
    <div className={styles.left_side}>
      <div className={styles.user_block}>
        <div className={styles.user_avatar}>
          <img
            src={
              userInfoResponse?.picture_url
                ? userInfoResponse.picture_url
                : "https://i.pinimg.com/564x/25/ee/de/25eedef494e9b4ce02b14990c9b5db2d.jpg"
            }
            alt="user avatar"
          />
        </div>
        <SecondTitle weight="400" fz="20px" style={{ minHeight: "24px" }}>
          {userInfoResponse?.full_name || ""}
        </SecondTitle>
      </div>
      <ul className={styles.list}>
        <>
        <li
            onClick={() => navigate("/account/myprofile")}
            className={
              location.pathname == "/account/myprofile"
                ? styles.active_li
                : styles.inactive_li
            }
          >
            My Profile
          </li>
          
          <li
            onClick={() => handleNavigationToRestrictedContent("/account/myads")}
            className={
              location.pathname === "/account/myads" ||
              (location.pathname === "/account/myarchivedads")
                ? styles.active_li
                : styles.inactive_li
            }
          >
            My Listings
          </li>

          <li
            onClick={() => handleNavigationToRestrictedContent("/account/subscription")}
            className={
              location.pathname == "/account/subscription"
                ? styles.active_li
                : styles.inactive_li
            }
          >
            Subscription
          </li>
        </>
      </ul>
      <Button
        style={{
          paddingLeft: "0px",
          justifyContent: "left",
        }}
        $icon
        $iconBgColor="#F5F6F6"
        className={styles.logout_btn}
        onClick={handleLogout}
      >
        Log out of profile
      </Button>
      <ModalComponent>
        <Login isOpen={isOpen} openModal={openModal} closeModal={closeModal} />
      </ModalComponent>
    </div>
  );
};

export default Sidebar;
