import React from "react";
import { Title } from "../../shared/title/Title";
import { Text } from "../../shared/Text/Text";
import { Button } from "../../shared/button/Button";
import clsx from "./ui/index.module.css";
import { useNavigate } from "react-router-dom";
import { getRedirectLink } from "../../shared/helpers/getRedirectLink";

const TravelWithFlatSharing = () => {
  const navigate = useNavigate();
  return (
    <div className={`container ${clsx.wrapper}`}>
      <Title>
      Stop overpaying <br /> landlords on Airbnb
      </Title>
      <Text>
      They are already doing just fine
      </Text>
      <Button onClick={() => navigate(getRedirectLink("TravelWithFlatSharing"))} $bg $icon>
        Apply now
      </Button>
    </div>
  );
};

export default TravelWithFlatSharing;
