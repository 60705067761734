import React from 'react';
import styles from './Gift.module.css';

interface GiftDescriptionProps {
  text?: string;
}

const defaultProps = {
  text: "We're so excited that you're joining us!",
};

const GiftDescription: React.FC<GiftDescriptionProps> = ({ 
  text = defaultProps.text 
}) => {
  return (
    <div className={styles.description}>
      {text}
    </div>
  );
};

export default GiftDescription; 