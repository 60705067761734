import React from 'react';
import styles from './Navigation.module.css';
import { BackArrow, NextButton, PageTitle, StrikeThroughTitle } from './index';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/redux/store';

// HeaderNavigation component for back button and title
interface HeaderNavigationProps {
  title?: string;
  showBackArrow?: boolean;
  onBack?: () => void;
}

export const HeaderNavigation: React.FC<HeaderNavigationProps> = ({
  title,
  showBackArrow = true,
  onBack,
}) => {
  const { currentStep } = useSelector((state: RootState) => state.onboarding);
  
  const renderTitle = () => {
    // Special case for Step16 - title with strikethrough
    if (currentStep === 16 && title) {
      return (
        <StrikeThroughTitle 
          text="Try Flatsharing for 3 days" 
          strikeText="3" 
          replacementText="7" 
        />
      );
    }
    
    // Regular title for other steps
    if (title) {
      return <PageTitle text={title} />;
    }
    
    return null;
  };

  if (currentStep === 1) {
    return null; // Hide navigation on step 1
  }

  return (
    <div className={styles.pageNavContainer}>
      {showBackArrow && <BackArrow onClick={onBack} />}
      {renderTitle()}
    </div>
  );
};

// FooterNavigation component for next button
interface FooterNavigationProps {
  showNextButton?: boolean;
  nextButtonLabel?: string;
  onNext?: () => void;
  nextButtonDisabled?: boolean;
}

export const FooterNavigation: React.FC<FooterNavigationProps> = ({
  showNextButton = true,
  nextButtonLabel = 'Next',
  onNext,
  nextButtonDisabled = false
}) => {
  if (!showNextButton) {
    return null;
  }

  return (
    <div className={styles.bottomButtonContainer}>
      <NextButton 
        onClick={onNext} 
        label={nextButtonLabel} 
        disabled={nextButtonDisabled} 
      />
    </div>
  );
};

// Main PageNavigation component that uses the new components
interface PageNavigationProps {
  title?: string;
  showBackArrow?: boolean;
  showNextButton?: boolean;
  nextButtonLabel?: string;
  onBack?: () => void;
  onNext?: () => void;
  nextButtonDisabled?: boolean;
}

const PageNavigation: React.FC<PageNavigationProps> = (props) => {
  return (
    <>
      <HeaderNavigation 
        title={props.title}
        showBackArrow={props.showBackArrow}
        onBack={props.onBack}
      />
      
      <FooterNavigation 
        showNextButton={props.showNextButton}
        nextButtonLabel={props.nextButtonLabel}
        onNext={props.onNext}
        nextButtonDisabled={props.nextButtonDisabled}
      />
    </>
  );
};

export default PageNavigation; 