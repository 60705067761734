import styles from "./ui/ui.module.css";
import { useNavigate } from "react-router-dom";
import { ChangeEvent, FC, useState, useEffect } from "react";

import { SecondTitle } from "../../../shared/secondTitle/SecondTitle";
import { Text } from "../../../shared/Text/Text";
import { Input } from "../../../shared/input/Input";
import { Button } from "../../../shared/button/Button";
import { Linked } from "../../../shared/Linked/Linked";
import Spinner from "../../../shared/ui/Spinner";

import {
  useForgotPasswordMutation,
  useLoginMutation,
  authApi
} from "../../../app/redux/auth/authApi";

import closeIcon from "../../../assets/icons/close.svg";
import {
  FormDataTypes,
  ModalProps,
  ResetPasswordProps,
} from "./model/types";
import { emailRegEx } from "../../../app/constants/regex";
import telegramIcon from "../../../assets/icons/telegram.svg";

// Helper components
const ResetPasswordDone: FC<ResetPasswordProps> = ({ email }) => {
  return (
    <>
      <div className={styles.content_block}>
        <SecondTitle fz="40px" mb="40px">
          Done! To restore your account, click on the link in the e-mail
        </SecondTitle>
        <Text>The letter went to {email}</Text>
      </div>
      <br />
    </>
  );
};

// Extended interface to include isPage flag
interface LoginFormProps extends ModalProps {
  isPage?: boolean;
}

const LoginForm: FC<LoginFormProps> = ({
  closeModal: closeLoginModal,
  isForgot = false,
  isAccess = false,
  isPage = false,
  isOpen
}) => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState<FormDataTypes>({
    email: "",
    password: "",
  });
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [checked, setChecked] = useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  
  const [isForgotPassword, setIsForgotPassword] = useState(isForgot);
  const [loading, setLoading] = useState(false);

  const [login] = useLoginMutation();
  const [getUserEmail] = authApi.endpoints.getUserEmail.useLazyQuery();
  const [resetPassword] = useForgotPasswordMutation();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value ? "" : prevErrors[name],
    }));
  };

  const handleResetPassword = async () => {
    const newErrors: { [key: string]: string } = {};
    if (!emailRegEx.test(forgotPasswordEmail)) {
      newErrors.error = "Please enter a valid email address";
      setErrors(newErrors);
      return;
    }
    try {
      setLoading(true);
      const { data: resetPasswordResult } = await resetPassword({
        data: {
          email: forgotPasswordEmail,
        },
      });
      if (resetPasswordResult == null) {
        setShowSuccessMessage(true);
      }
    } catch (error) {
      newErrors.error =
        "The user was not found. Check the entered e-mail address and try again.";
      setErrors(newErrors);
    } finally {
      setLoading(false);
    }
  };

  const handleSuccessfulLogin = (userEmailData: any) => {
    if (userEmailData) {
      // Dispatch storage event to notify other components about auth change
      window.dispatchEvent(new Event('storage'));
      
      // Add a longer delay before redirecting to prevent the modal from blinking
      // This single operation will close the modal once and redirect, preventing flickering
      setTimeout(() => {
        if (userEmailData.is_accepted) {
          // First close the modal
          closeLoginModal();
          // Then do the redirect after a small additional delay
          setTimeout(() => {
            window.location.href = '/';
          }, 100);
        } else {
          // First close the modal
          closeLoginModal();
          // Then do the redirect after a small additional delay
          setTimeout(() => {
            navigate('/account/myprofile');
          }, 100);
        }
      }, 100); // Longer delay to ensure the login process completes
    }
  };

  const handleLogin = async () => {
    const loginData = new FormData();
    loginData.append("username", formData.email);
    loginData.append("password", formData.password);
    const newErrors: { [key: string]: string } = {};

    try {
      setLoading(true);
      const { data: loginResult, error: loginError } = await login({
        user: loginData,
      });
      
      if (loginResult) {
        // Set the token first
        localStorage.setItem("token", loginResult.access_token);
        // Dispatch storage event to notify other components about auth change
        window.dispatchEvent(new Event('storage'));
        
        // Then fetch user data in sequence, not parallelly
        try {
          const { data: userEmailData } = await getUserEmail({});
          if (userEmailData) {
            // Only proceed with login flow if we have valid data
            localStorage.setItem("userEmailData", JSON.stringify(userEmailData));
            handleSuccessfulLogin(userEmailData);
          }
        } catch (userFetchError) {
          console.error("Error fetching user data:", userFetchError);
          newErrors.error = "Error fetching user profile. Please try again.";
          setErrors(newErrors);
        }
      }
      else if (loginError) {
        newErrors.error = "Invalid email or password.";
        setErrors(newErrors);
      }
    } catch (error) {
      console.log(error);
      newErrors.error = "Invalid email or password.";
      setErrors(newErrors);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        handleLogin();
        event.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleLogin, handleResetPassword]);

  // For the page version, we'll modify the wrapper classes
  const wrapperClasses = isPage ? styles.page_wrapper : styles.content_wrapper;
  const contentClasses = isPage ? `${styles.content} ${styles.page_content}` : styles.content;

  return (
    <div
      className={wrapperClasses}
      onClick={(e) => {
        // Make sure this stops the event from bubbling to the modal backdrop
        e.stopPropagation();
      }}
    >
      {errors && errors.error && (
        <div className={styles.errors}>
          <div>{errors.error}</div>
        </div>
      )}
      <div className={contentClasses}>
        {!isPage && (
          <div
            className={styles.close_icon}
            onClick={() => {
              closeLoginModal();
            }}
          >
            <img src={closeIcon} alt="close icon" />
          </div>
        )}

        {loading && (
          <div className={styles.spinner}>
            <Spinner overlay={true} />
          </div>
        )}

        {showSuccessMessage && isForgotPassword ? (
          <ResetPasswordDone email={forgotPasswordEmail} />
        ) : (
          <>
            {isForgotPassword ? (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleResetPassword();
                }}
                className={styles.content_block}
              >
                <SecondTitle mb="20px">Forgot your password?</SecondTitle>
                <Text style={{ marginBottom: "30px" }}>
                  Enter the email address associated with your account and we'll
                  send you a link to reset your password.
                </Text>
                <div className={styles.inputs}>
                  <Input
                    $error={errors?.email ? true : false}
                    required
                    placeholder="E-mail"
                    type="email"
                    name="email"
                    value={forgotPasswordEmail}
                    onChange={(e) => setForgotPasswordEmail(e.target.value)}
                  />
                </div>

                <Button style={{ marginTop: "30px" }} type="submit" $bg $icon>
                  Continue
                </Button>
              </form>
            ) : (
              <>
                <form id="loginForm" className={styles.content_block}>
                  <SecondTitle mb="40px" style={isPage ? {textAlign: 'center'} : undefined}>Log in</SecondTitle>
                  <div className={styles.inputs}>
                    <Input
                      $error={errors?.email ? true : false}
                      required
                      placeholder="E-mail"
                      type="text"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    <Input
                      $error={errors?.password ? true : false}
                      required
                      placeholder="Password"
                      type={checked ? "text" : "password"}
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                    />
                    <div className={styles.check_block}>
                      <div className={styles.check}>
                        <input
                          onChange={() => setChecked(!checked)}
                          type="checkbox"
                          name=""
                          id="chekc"
                        />
                        <label htmlFor="chekc">Show password</label>
                      </div>
                      <Text
                        fw="500"
                        onClick={() => {
                          setIsForgotPassword(true);
                        }}
                      >
                        Forgot your password?
                      </Text>
                    </div>
                  </div>

                  <Button 
                    type="button" 
                    onClick={handleLogin} 
                    $bg 
                    $icon
                    style={isPage ? {width: 'fit-content', margin: '0 auto'} : undefined}
                  >
                    Sign In
                  </Button>
                </form>
                
                <div className={styles.link} style={{marginTop: '10px', ...isPage ? {justifyContent: 'center'} : undefined}}>
                    <Text fw="500">I have an </Text> 
                    <Linked
                      onClick={() => {
                        closeLoginModal();
                        navigate("/telegram-login");
                      }}
                      $weight="500"
                      $underline
                    >
                      account in Telegram
                    </Linked>
                    <div 
                      onClick={() => {
                        closeLoginModal();
                        navigate("/telegram-login");
                      }}
                      style={{cursor: 'pointer', marginLeft: '8px'}}
                    >
                      <img src={telegramIcon} alt="Telegram" width="20" height="20" />
                    </div>
                </div>
                
                <div className={styles.link} style={{marginTop: '15px', ...isPage ? {justifyContent: 'center'} : undefined}}>
                  <Text fw="500">Don't have an account? </Text>
                  <Linked
                    onClick={() => {
                      closeLoginModal();
                      navigate("/onboarding?utm_source=login");
                    }}
                    $weight="500"
                    $underline
                  >
                    Join Now
                  </Linked>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default LoginForm; 