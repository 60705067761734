import React, { useEffect } from 'react';
import { QuestionIcon, QuestionText, OptionButton } from '../components/Question';
import useOnboardingData from '../../../hooks/useOnboardingData';
import styles from '../components/Navigation/Navigation.module.css';

interface Step4Props {
  onNext: () => void;
  onPrev: () => void;
}

// Travel frequency options
const travelOptions = [
  { id: 'once', label: 'Once a year' },
  { id: '2-5', label: '2-5 times a year' },
  { id: 'more', label: 'More than 5 times a year' }
];

const Step4: React.FC<Step4Props> = ({ onNext, onPrev }) => {
  // Use our custom hook for data management
  const { data: selectedOption, setData: setSelectedOption, saveData } = 
    useOnboardingData<string | null>(null, 'travelFrequency');

  const handleOptionClick = (optionId: string) => {
    setSelectedOption(optionId);
    
    // Save data and move to next step after a short delay
    setTimeout(() => {
      saveData(optionId); // Explicitly save the selected option
      onNext();
    }, 300);
  };

  return (
    <div className="onboarding-step">
      <div className="step-content">
        <QuestionIcon />
        <QuestionText text="Tell us how often you travel" />
        
        <div className="options-container">
          {travelOptions.map((option) => (
            <OptionButton
              key={option.id}
              label={option.label}
              selected={selectedOption === option.id}
              onClick={() => handleOptionClick(option.id)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Step4; 