import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { saveStepData } from '../app/redux/onboarding/onboardingSlice';
import { saveOnboardingData, getOnboardingDataItem } from '../utils/localStorage';

/**
 * Custom hook for managing onboarding data collection
 * @param initialData Initial data value (string, array, object, etc.)
 * @param key The key to store the data under
 * @param saveOnNext Whether to save data when next is clicked (alternative is to save when component unmounts)
 * @returns Data management utilities
 */
export function useOnboardingData<T>(
  initialData: T, 
  key: string, 
  saveOnNext: boolean = true
) {
  const [data, setData] = useState<T>(initialData);
  const dispatch = useDispatch();
  const isInitialMount = useRef(true);

  // Load data from localStorage on initial mount only
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      
      try {
        // Determine if we should parse the stored value as an array
        const shouldParseArray = Array.isArray(initialData);
        
        // Get data from localStorage
        const storedValue = getOnboardingDataItem(key, shouldParseArray);
        
        // Only update state if stored value exists
        if (storedValue !== null && storedValue !== undefined) {
          console.log(`useOnboardingData: Loading ${key} from localStorage:`, storedValue);
          setData(storedValue as T);
        }
      } catch (error) {
        console.error(`Error loading data for ${key}:`, error);
      }
    }
  }, [key, initialData]);

  // Save data to both Redux and localStorage
  const saveData = (value: T = data) => {
    try {
      // For array data, convert to comma-separated string for localStorage
      const storageValue = Array.isArray(value) ? value.join(',') : value;
      
      // Save to Redux
      dispatch(saveStepData({ [key]: value }));
      
      // Save to localStorage
      saveOnboardingData(key, storageValue);
      
      console.log(`useOnboardingData: Saved ${key}:`, value);
    } catch (error) {
      console.error(`Error saving data for ${key}:`, error);
    }
  };

  // Handle next button click - save data if configured that way
  const handleNext = (onNext: () => void) => {
    if (saveOnNext) {
      saveData();
    }
    onNext();
  };

  return {
    data,
    setData,
    saveData,
    handleNext
  };
}

export default useOnboardingData; 