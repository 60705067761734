import React from 'react';
import styles from './TrialOption.module.css';

interface PriceTitleProps {
  text?: string;
}

const defaultProps = {
  text: 'Choose a price for your 7-day trial:',
};

const PriceTitle: React.FC<PriceTitleProps> = ({ 
  text = defaultProps.text 
}) => {
  return (
    <div className={styles.priceTitle}>
      {text}
    </div>
  );
};

export default PriceTitle; 