import React from 'react';
import { 
  Title, 
  SubtitleText, 
  InputField, 
  Button, 
  LoginLink 
} from '../components/Registration';
import useOnboardingData from '../../../hooks/useOnboardingData';
import styles from '../components/Navigation/Navigation.module.css';

interface Step13Props {
  onNext: () => void;
  onPrev: () => void;
}

// Email validation regex pattern
const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const Step13: React.FC<Step13Props> = ({ onNext, onPrev }) => {
  // Use our custom hook for data management
  const { data: email, setData: setEmail, handleNext: handleNextWithSave } = 
    useOnboardingData<string>('', 'email');

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  // Validate email with regex
  const isValidEmail = (email: string): boolean => {
    return EMAIL_REGEX.test(email.toLowerCase());
  };

  const handleSubmit = () => {
    // Save email and move to next step only if it's valid
    if (isValidEmail(email)) {
      handleNextWithSave(onNext);
    }
  };

  return (
    <div className="onboarding-step">
      <div className="step-content" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Title text="Create your FlatSharing account" />
        <SubtitleText text="Save your progress in seconds" />
        
        <InputField 
          text="Email Adress" 
          value={email}
          onChange={handleEmailChange}
        />
        
        <Button 
          label="Join Flatsharing" 
          onClick={handleSubmit}
          disabled={!isValidEmail(email)}
        />
        
        <LoginLink />
      </div>
    </div>
  );
};

export default Step13; 