import React, { useEffect } from 'react';
import { LocationCard, LocationQuestion } from '../components/Location';
import europeIcon from '../../../assets/icons/globe-europe.svg';
import asiaIcon from '../../../assets/icons/globe-asia.svg';
import americasIcon from '../../../assets/icons/globe-americas.svg';
import globeIcon from '../../../assets/icons/globe.svg';
import useOnboardingData from '../../../hooks/useOnboardingData';
import styles from '../components/Navigation/Navigation.module.css';

interface Step6Props {
  onNext: () => void;
  onPrev: () => void;
  updateNextButtonState?: (isEnabled: boolean) => void;
  saveStepData?: (key: string, value: any) => void; // Kept for backward compatibility
}

// Location options
const locationOptions = [
  { id: 'europe', name: 'Europe', icon: europeIcon },
  { id: 'asia', name: 'Asia', icon: asiaIcon },
  { id: 'usa', name: 'USA', icon: americasIcon },
  { id: 'other', name: 'Other', icon: globeIcon }
];

const Step6: React.FC<Step6Props> = ({ onNext, onPrev, updateNextButtonState }) => {
  // Use our custom hook for data management
  const { data: selectedLocations, setData: setSelectedLocations, handleNext: handleNextWithSave } = 
    useOnboardingData<string[]>([], 'locations');

  // Update the parent component's next button state whenever selections change
  useEffect(() => {
    const isValid = selectedLocations.length > 0;
    if (updateNextButtonState) {
      updateNextButtonState(isValid);
    }
  }, [selectedLocations, updateNextButtonState]);

  // Handle next button click
  const handleNext = () => {
    // This function isn't being used anymore since we're using the standard next button
    // But we'll keep it updated in case it's needed in the future
    handleNextWithSave(onNext);
  };

  const handleLocationClick = (locationId: string) => {
    setSelectedLocations(prev => {
      // If already selected, remove it (toggle)
      if (prev.includes(locationId)) {
        return prev.filter(id => id !== locationId);
      }
      // Otherwise add it
      return [...prev, locationId];
    });
  };

  return (
    <div className="onboarding-step">
      <div className="step-content">
        {/* <LocationQuestion text="Which locations do you prefer?" /> */}
        
        <div className="location-grid">
          {locationOptions.map(location => (
            <LocationCard
              key={location.id}
              id={location.id}
              name={location.name}
              icon={location.icon}
              selected={selectedLocations.includes(location.id)}
              onClick={handleLocationClick}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Step6; 