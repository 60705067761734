import React from 'react';
import styles from './Content.module.css';

interface OnboardingFirstImageProps {
  image?: string;
}

const defaultProps = {
  image: 'https://assets.api.uizard.io/api/cdn/stream/c2967a45-dcc3-4eb8-a41e-f9c3d46b9e04.png',
};

const OnboardingFirstImage: React.FC<OnboardingFirstImageProps> = ({ 
  image = defaultProps.image 
}) => {
  return (
    <div 
      className={styles.imageContainer}
      style={{
        backgroundImage: `url(${image})`,
      }} 
    />
  );
};

export default OnboardingFirstImage; 