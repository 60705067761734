import React from 'react';
import styles from './Community.module.css';

interface CommunityDescriptionProps {
  text: string;
}

const defaultProps = {
  text: 'Besides renting apartments, we\'re also a community where people have found friends, buddies, and even jobs! Our community includes executives of major companies, well-known creative individuals, and genuinely great people who are always ready to help.',
};

const CommunityDescription: React.FC<CommunityDescriptionProps> = ({ 
  text = defaultProps.text 
}) => {
  return (
    <div className={styles.communityDescription}>
      {text}
    </div>
  );
};

export default CommunityDescription; 