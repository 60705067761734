import styled from "styled-components";

export const Lessor = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  z-index: 1;
  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
