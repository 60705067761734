import { ChangeEvent, useEffect, useState } from "react";
import styles from "../ui/index.module.css";
import { Title } from "../../../shared/title/Title";
import { SecondTitle } from "../../../shared/secondTitle/SecondTitle";
import { Input } from "../../../shared/input/Input";
import replaceIcon from "../../../assets/icons/button replace photo.svg";
import { Text } from "../../../shared/Text/Text";
import { Button } from "../../../shared/button/Button";
import {
  useEditUserInfoMutation,
  useGetUserEmailQuery,
  useGetUserQuery,
  useUpdateUserPictureMutation,
} from "../../../app/redux/auth/authApi";
import { useModal } from "../../../app/helpers/hooks/useModal";
import { FormDataTypes } from "./model/types";
import { useNavigate } from "react-router-dom";
import backIcon from "../../../assets/icons/arrow_toLeft.svg";
import Spinner from "../../../shared/ui/Spinner";
import { SuccessPopup } from '../../../shared/ui/Popup/SuccessPopup';
import { ErrorPopup } from '../../../shared/ui/Popup/ErrorPopup';
import { MultilineInput } from "../../../shared/input/Input";
import ContactsPopUp from "../../contacts/ContactsPopUp";

const MyProfile = () => {
  const navigate = useNavigate();
  const { data: userInfo, isLoading } = useGetUserQuery({});
  const { data: userEmail } = useGetUserEmailQuery({});
  const [edit] = useEditUserInfoMutation();
  const [imageUpdate] = useUpdateUserPictureMutation();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  // State to track the success message
  const [successMessage, setSuccessMessage] = useState('Profile updated successfully');
  // State to track error message
  const [errorMessage, setErrorMessage] = useState('Failed to update profile');

  const { isOpen, closeModal, openModal, ModalComponent } = useModal();

  const [formData, setFormData] = useState<FormDataTypes>({
    id: "",
    email: userEmail?.email || "",
    full_name: "",
    instagram: "",
    linkedin: "",
    telegram: "",
    whatsapp: "",
    description: "",
  });
  const [imageFile, setImageFile] = useState<File | null>(null);
  
  // Check if email ends with @t.me
  const isTelegramUser = userEmail?.email?.endsWith('@t.me');

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    if (e.target.type === 'file') {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (file) {
        setImageFile(file);
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleClick = () => {
    const fileInput = document.getElementById("fileInput") as HTMLInputElement;
    fileInput.click();
  };

  const handleEdit = async () => {
    const editedData = {
      full_name: formData.full_name,
      telegram: formData.telegram,
      whatsapp: formData.whatsapp,
      instagram: formData.instagram,
      linkedin: formData.linkedin,
      description: formData.description,
      where_to_let: formData.where_to_let,
      where_to_rent: formData.where_to_rent,
      user_id: formData.id,
    };
    try {
      await edit({
        user: editedData,
      });
      if (imageFile) {
        const form = new FormData();
        if (imageFile) {
          form.append("file", imageFile);
        }
        await imageUpdate({ data: form });
      }

      // Set success message for profile update
      setSuccessMessage('Profile updated successfully');
      // Show success popup
      setShowSuccess(true);
      
      // Don't reload data from server - keep the user's edited data
      // The data will be properly synced next time the component loads
    } catch (error) {
      console.error("Failed to update profile:", error);
      // Provide a more descriptive error message
      let errorMsg = 'Failed to update profile';
      
      if (error instanceof Error) {
        errorMsg = `Error updating profile: ${error.message}`;
      } else if (typeof error === 'object' && error !== null) {
        // Try to extract more information from the error object
        const errorObj = JSON.stringify(error, null, 2);
        errorMsg = `Profile update failed: ${errorObj}`;
      }
      
      setErrorMessage(errorMsg);
      setShowError(true);
    }
  };

  const selectFormData = () => {
    // Only update form data if we have userInfo data
    if (userInfo && userEmail) {
      setFormData({
        // @ts-ignore
        id: userInfo?.user_id || "",
        email: userEmail?.email || "",
        where_to_let: userInfo?.where_to_let || "",
        where_to_rent: userInfo?.where_to_rent || "",
        full_name: userInfo?.full_name || "",
        instagram: userInfo?.instagram || "",
        linkedin: userInfo?.linkedin || "",
        telegram: userInfo?.telegram || "",
        whatsapp: userInfo?.whatsapp || "",
        description: userInfo?.description || "",
      });
    }
  };

  // Call selectFormData whenever userInfo or userEmail changes
  useEffect(() => {
    // Only load data from server on initial mount or when explicitly refreshing
    if (
      // Check if formData is empty (initial state)
      !formData.full_name && 
      !formData.description && 
      !formData.instagram && 
      !formData.linkedin && 
      !formData.telegram && 
      !formData.whatsapp
    ) {
      selectFormData();
    }
  }, [userInfo, userEmail, formData]);

  useEffect(() => {
    // Check URL parameter
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get('success') === 'true') {
      setShowSuccess(true);
    }
    
    // Check localStorage flag from Telegram login
    const showAuthSuccess = localStorage.getItem("showAuthSuccess");
    if (showAuthSuccess === "true") {
      setSuccessMessage('Successfully signed in');
      setShowSuccess(true);
      // Remove the flag to avoid showing popup again on refresh
      localStorage.removeItem("showAuthSuccess");
    }
  }, []);

  if (isLoading) return <Spinner />;

  return (
    <div className={styles.right_side}>
      <SuccessPopup 
        show={showSuccess} 
        onHide={() => setShowSuccess(false)} 
        message={successMessage}
      />
      <ErrorPopup 
        show={showError} 
        onHide={() => setShowError(false)} 
        message={errorMessage}
      />
      
      <Title $fz="50px" style={{ marginBottom: "50px" }}>
        My Profile
      </Title>
      {userEmail?.is_accepted === false && (
        <div className={styles.info_wrapper} style={{ marginBottom: "30px" }}>
          <Text>
            We got your access request and will contact you on email about the progress on it. 
            It usually takes several hours to a day to process. 
            Meanwhile you can edit your personal data and validate your email for the ease of request processing.
          </Text>
        </div>
      )}
      {/* <div className={styles.mobile_navigation}>
        <MobileNav currentPath="/account/myprofile" />
      </div> */}
      <div className={styles.info_wrapper}>
        <div className={styles.personal_info}>
          <div className={styles.personal_info_text}>
            {!isTelegramUser && (
              <div className={styles.personal_info_item}>
                <SecondTitle fz="24px">Email</SecondTitle>
                <Input
                  name="email"
                  value={formData.email}
                  placeholder="Email"
                />
              </div>
            )}
            <SecondTitle fz="24px">Full Name</SecondTitle>
            <Input
              name="full_name"
              onChange={handleChange}
              value={formData.full_name}
              placeholder="Full Name"
            />
          </div>
          <div className={styles.personal_info_avatar}>
            <input
              type="file"
              accept="image/*"
              onChange={handleChange}
              style={{ display: "none" }}
              id="fileInput"
            />
            <img
              className={styles.user_img}
              src={
                imageFile
                  ? URL.createObjectURL(imageFile)
                  : // @ts-ignore
                  userInfo?.picture_url
                  ? // @ts-ignore
                    userInfo?.picture_url
                  : "https://i.pinimg.com/564x/25/ee/de/25eedef494e9b4ce02b14990c9b5db2d.jpg"
              }
              alt="user avatar"
            />
            <img
              onClick={handleClick}
              className={styles.user_img_icon}
              src={replaceIcon}
              alt="replase icon"
            />
          </div>
        </div>
        <div className={styles.personal_info_item}>
          <SecondTitle fz="24px">Your description</SecondTitle>
          <MultilineInput
            name="description"
            onChange={handleChange}
            value={formData.description}
            placeholder="Enter your profession, hobbies and interests"
          />
        </div>
        <div className={styles.personal_info_item}>
          <SecondTitle fz="24px">Where do you want to let your apartment?</SecondTitle>
          <Input
            name="where_to_let"
            onChange={handleChange}
            value={formData.where_to_let}
            placeholder="Madrid, Spain"
          />
        </div>
        <div className={styles.personal_info_item}>
          <SecondTitle fz="24px">Where do you want to rent?</SecondTitle>
          <Input
            name="where_to_rent"
            onChange={handleChange}
            value={formData.where_to_rent}
            placeholder="Italy, Rome"
          />
        </div>
        <div className={styles.personal_info_item}>
          <SecondTitle fz="24px">
            Are you interested in participating in regular community meetings
            dedicated to travel, visas, relocation and taxes?
          </SecondTitle>
          <div className={styles.radio}>
            <div>
              <input type="radio" name="want_to_let" value="Yes" checked />
              <p>Yes</p>
            </div>
            <div>
              <input type="radio" name="want_to_let" value="No" />
              <p>No</p>
            </div>
          </div>
        </div>
        <div className={styles.personal_info_item}>
          <SecondTitle fz="24px">Social networks</SecondTitle>
          <Text style={{ marginBottom: "20px" }}>
            Help people to contact you by adding your social media pages.
          </Text>
          <div className={styles.personal_info_item}>
            <Input
              name="instagram"
              onChange={handleChange}
              value={formData.instagram}
              placeholder="Instagram"
            />
            <Input
              name="linkedin"
              onChange={handleChange}
              value={formData.linkedin}
              placeholder="LinkedIn"
            />
            <Input
              name="telegram"
              onChange={handleChange}
              value={formData.telegram}
              placeholder="Telegram"
            />
            <Input
              name="whatsapp"
              onChange={handleChange}
              value={formData.whatsapp}
              placeholder="WhatsApp"
            />
          </div>
        </div>
        <Button $bg $icon type="button" onClick={handleEdit}>
          Save
        </Button>
        <Text fw="500" style={{ marginTop: "20px" }}>
          How my profile{" "}
          <span
            onClick={openModal}
            style={{
              textDecoration: "underline",
              fontWeight: "500",
              cursor: "pointer",
            }}
          >
            is seen by other community members
          </span>
        </Text>
      </div>

      <ModalComponent>
        <ContactsPopUp
          userInfo={userInfo}
          userEmail={userEmail}
          closeModal={closeModal}
          openModal={openModal}
          isOpen={isOpen}
        />
      </ModalComponent>
    </div>
  );
};

export default MyProfile;
