import React from 'react';
import { Feature, FeaturesContainer, PurpleIcon } from '../components/HowItWorks';
import userCheckIcon from '../../../assets/icons/user-check.svg';
import handshakeIcon from '../../../assets/icons/handshake.svg';
import dollarSignIcon from '../../../assets/icons/dollar-sign.svg';

interface Step10Props {
  onNext: () => void;
  onPrev: () => void;
}

const Step10: React.FC<Step10Props> = ({ onNext, onPrev }) => {
  return (
    <div className="onboarding-step">
      <div className="step-content">
        <FeaturesContainer>
          <Feature 
            icon={<PurpleIcon src={userCheckIcon} alt="LinkedIn Verified" />}
            title="LinkedIn Verified Community"
            description="We add you to a private portal where you can post and view listings"
          />
          
          <Feature 
            icon={<PurpleIcon src={handshakeIcon} alt="Exclusive Listings" />}
            title="Exclusive Member Listings"
            description="Our rentals are offered only at cost and exclusively by people in IT, entrepreneurship, and creative fields."
          />
          
          <Feature 
            icon={<PurpleIcon src={dollarSignIcon} alt="Cost-Effective" />}
            title="Cost-Effective Rentals"
            description="This ensures our rates are lower than those on Airbnb or Booking."
          />
        </FeaturesContainer>
      </div>
    </div>
  );
};

export default Step10; 