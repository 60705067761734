import { Button } from "../../../shared/button/Button";
import { SecondTitle } from "../../../shared/secondTitle/SecondTitle";
import { Text } from "../../../shared/Text/Text";
import { Title } from "../../../shared/title/Title";
// import MobileNav from "../SideBar/MobileNav";

import styles from "../ui/index.module.css";
import { useGetUserEmailQuery } from "../../../app/redux/auth/authApi";
import Spinner from "../../../shared/ui/Spinner";
import { useHandlePayment } from "../../../app/helpers/payment";

const Subscription = () => {
  const { data: userResponse } = useGetUserEmailQuery({});
  const handlePayment = useHandlePayment();

  if (!userResponse) {
    return <Spinner />;  // Add a loading state while fetching data
  }

  const isPremiumActive = () => {
    if (!userResponse.premium_until) return false;
    return new Date(userResponse.premium_until) > new Date();
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  return (
    <div style={{ marginBottom: "50px" }} className={styles.right_side}>
      {/* <div className={styles.mobile_navigation}>
        <MobileNav currentPath="/account/subscription" />
      </div> */}
      <Title $fz="50px" style={{ marginBottom: "50px" }}>
        Subscription
      </Title>
      <SecondTitle style={{ marginBottom: "12px" }}>
        {isPremiumActive() ? "Subscription is active" : "No access"}
      </SecondTitle>
      {!isPremiumActive() && userResponse.premium_until && (
        <Text className={styles.card_info_text}>
          Access ended on {formatDate(userResponse.premium_until)}
        </Text>
      )}
      {!isPremiumActive() && (
        <Button
          onClick={() => handlePayment(userResponse)}
          $bg
          $icon
        >
          Get new subscription
        </Button>
      )}
      {isPremiumActive() && (
        <Text className={styles.card_info_text}>
          Active until {formatDate(userResponse.premium_until)}
        </Text>
      )}
      <Button 
        $bg 
        $icon 
        onClick={() => window.open('https://billing.stripe.com/p/login/dR60019miahcfpm9AA', '_blank')}
        style={{ marginTop: '20px' }}
      >
        Manage current subscription
      </Button>
      <Text style={{ marginTop: '20px', color: '#666', fontSize: '14px' }}>
        Note: Your Stripe billing email may differ from your FlatSharing account email
      </Text>
    </div>
  );
};

export default Subscription;
