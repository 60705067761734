import React from 'react';
import styles from './TrialOption.module.css';

interface PriceOptionProps {
  price: string;
  selected: boolean;
  onClick: () => void;
}

const PriceOption: React.FC<PriceOptionProps> = ({ price, selected, onClick }) => {
  return (
    <div className={styles.priceCard} onClick={onClick}>
      <span className={styles.priceText}>{price}</span>
      <div className={selected ? styles.selectedDot : styles.unselectedDot} />
    </div>
  );
};

export default PriceOption; 