import React from 'react';
import styles from './Privacy.module.css';

interface PrivacyTitleProps {
  text: string;
}

const defaultProps = {
  text: 'Privacy First',
};

const PrivacyTitle: React.FC<PrivacyTitleProps> = ({ 
  text = defaultProps.text 
}) => {
  return (
    <div className={styles.title}>
      {text}
    </div>
  );
};

export default PrivacyTitle; 