import React from 'react';
import styles from './Community.module.css';

interface CommunityHeadingProps {
  text: string;
}

const defaultProps = {
  text: 'Welcome to Our Community',
};

const CommunityHeading: React.FC<CommunityHeadingProps> = ({ 
  text = defaultProps.text 
}) => {
  return (
    <div className={styles.communityHeading}>
      {text}
    </div>
  );
};

export default CommunityHeading; 