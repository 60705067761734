import { Title } from "../../../shared/title/Title";
import Carousel from "../../../features/carousel/Carousel";
import CarouselItem from "../../../features/carousel/CarouselItem";
import { GreyCard } from "../../../shared/greyCard/GreyCard";
import { Text } from "../../../shared/Text/Text";
import { SecondTitle } from "../../../shared/secondTitle/SecondTitle";
import clsx from "./ui/index.module.css";
import { Button } from "../../../shared/button/Button";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { getRedirectLink } from "../../../shared/helpers/getRedirectLink";

import content from "./content.json";

const Recoll = () => {
  const navigate = useNavigate();
  const itemWidth = {
    0: 320,
    768: 380,
    1000: 380,
  };

  const [expandedItems, setExpandedItems] = useState<{
    [key: number]: boolean;
  }>({});

  const toggleExpand = (index: number) => {
    setExpandedItems((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  return (
    <div id="margin_top" className={`${clsx.recoll}`}>
      <div className="container">
        <Title>Our members have saved over $10 million!</Title>
        <Text
          style={{ marginTop: "10px", marginBottom: "20px", fontSize: "18px" }}
        >
          And here's what they think
        </Text>
      </div>

      <Carousel itemWidth={itemWidth}>
        {content.map((item, index) => (
          <CarouselItem
            key={index}
            style={{
              backgroundColor: "#f5f6f6",
              borderRadius: "10px",
            }}
          >
            <GreyCard
              id={clsx.grey_card}
              className={expandedItems[index] ? clsx.expanded : ""}
              style={{
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <div className={clsx.review_content}>
                <SecondTitle style={{ marginBottom: "14px" }}>
                  {item.title}
                </SecondTitle>
                <Text
                  className={`${clsx.review_text} ${
                    expandedItems[index] ? clsx.expanded : ""
                  }`}
                >
                  {item.description}
                </Text>
                {item.description.length > 300 && (
                  <button
                    className={clsx.expand_button}
                    onClick={() => toggleExpand(index)}
                  >
                    {expandedItems[index] ? "Read less" : "Read more"}
                  </button>
                )}
              </div>
              <div className={clsx.user_info}>
                <img
                  src={item.image}
                  alt=""
                  loading="lazy"
                  style={{ marginBottom: "14px", marginTop: "14px" }}
                />
                <SecondTitle style={{ marginBottom: "10px" }}>
                  {item.name}
                </SecondTitle>
                <Text className={clsx.position_text}>{item.position}</Text>
              </div>
            </GreyCard>
          </CarouselItem>
        ))}
      </Carousel>
      <div className={`container`}>
        <Button
          onClick={() => navigate(getRedirectLink("Recoll"))}
          $icon
          $bg
          style={{ 
            margin: "0 auto",
          }}
        >
          Try it yourself
        </Button>
      </div>
    </div>
  );
};

export default Recoll;
