import React from 'react';
import styles from './Content.module.css';

interface OnboardingHeadingProps {
  text?: string;
}

const defaultProps = {
  text: 'Hello, this is FlatSharing',
};

const OnboardingHeading: React.FC<OnboardingHeadingProps> = ({ 
  text = defaultProps.text 
}) => {
  return (
    <div className={styles.heading}>
      {text}
    </div>
  );
};

export default OnboardingHeading; 