import React from 'react';
import styles from './Question.module.css';

interface OptionButtonProps {
  label?: string;
  onClick?: () => void;
  selected?: boolean;
}

const defaultProps = {
  label: 'Once a year',
  selected: false,
};

const OptionButton: React.FC<OptionButtonProps> = ({ 
  label = defaultProps.label, 
  onClick,
  selected = defaultProps.selected
}) => {
  return (
    <button 
      className={`${styles.optionButton} ${selected ? styles.selected : ''}`}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default OptionButton; 