import styled from "styled-components";
import { AddressGreyWrapperProps } from "./model/types";

export const AddressGreyWrapper = styled.div<AddressGreyWrapperProps>`
  border-radius: 26px;
  background-color: #ffffffd6;
  padding: 16px 24px;
  position: relative;
  z-index: 1;
`;
