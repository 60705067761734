import React from 'react';
import styles from './Explore.module.css';

interface ExploreHeadingProps {
  text?: string;
}

const defaultProps = {
  text: 'Explore Apartments Worldwide',
};

const ExploreHeading: React.FC<ExploreHeadingProps> = ({ 
  text = defaultProps.text 
}) => {
  return (
    <div className={styles.exploreHeading}>
      {text}
    </div>
  );
};

export default ExploreHeading; 