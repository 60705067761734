import React, { useEffect } from 'react';
import { IndustryQuestion, HelperText, TagCloud } from '../components/Industry';
import useOnboardingData from '../../../hooks/useOnboardingData';
import styles from '../components/Navigation/Navigation.module.css';

interface Step8Props {
  onNext: () => void;
  onPrev: () => void;
  updateNextButtonState?: (isEnabled: boolean) => void;
  saveStepData?: (key: string, value: any) => void; // Kept for backward compatibility
}

// Industry tag options - exactly matching the layout with the same order
const industryTags = [
  // First row (2 tags)
  'Technology', 'Marketing',
  // Second row (2 tags)
  'Graphic Design', 'Scientific Research',
  // Third row (3 tags)
  'Finance', 'Healthcare', 'Legal',
  // Fourth row (3 tags)
  'Construction', 'Sales', 'Manufacturing',
  // Fifth row (3 tags)
  'Logistics', 'Education', 'Entertainment',
  // Sixth row (3 tags)
  'Energy', 'Hospitality', 'Other'
];

const Step8: React.FC<Step8Props> = ({ onNext, onPrev, updateNextButtonState }) => {
  // Use our custom hook for data management
  const { data: selectedTags, setData: setSelectedTags, handleNext: handleNextWithSave } = 
    useOnboardingData<string[]>([], 'industries');

  // Update the parent component's next button state whenever selections change
  useEffect(() => {
    const isValid = selectedTags.length > 0 && selectedTags.length <= 3;
    if (updateNextButtonState) {
      updateNextButtonState(isValid);
    }
  }, [selectedTags, updateNextButtonState]);

  // Handle next button click
  const handleNext = () => {
    handleNextWithSave(onNext);
  };

  const handleTagClick = (tag: string) => {
    setSelectedTags(prev => {
      // If already selected, remove it
      if (prev.includes(tag)) {
        return prev.filter(t => t !== tag);
      }
      // Otherwise add it if within limits
      if (prev.length < 3) {
        return [...prev, tag];
      }
      return prev;
    });
  };

  return (
    <div className="onboarding-step">
      <div className="step-content">
        {/* <IndustryQuestion text="What industry do you work in?" /> */}
        <HelperText text="Please specify up to 3 tags" />
        
        <TagCloud
          tags={industryTags}
          selectedTags={selectedTags}
          onTagClick={handleTagClick}
          maxSelections={3}
        />
      </div>
      
      <div className={styles.bottomButtonContainer}>
        <button 
          className={styles.nextButton}
          onClick={handleNext}
          disabled={selectedTags.length === 0 || selectedTags.length > 3}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Step8; 